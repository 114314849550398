import { useState } from 'react';
import { Typography, Box, Collapse, IconButton, styled } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { fontFamilies } from '@cfra-nextgen-frontend/shared/src/utils';

interface CourseContent {
    title: string;
    description: string;
    topics: { title: string; content: string | React.ReactNode }[];
}

interface ExpandableAccordionProps {
    content: CourseContent;
}

const StyledMediumBox = styled(Box)({
    cursor: 'pointer',
    margin: 0,
    padding: '16px 33px',
    borderRadius: '8px',
    '& .MuiTypography-root': {
        fontFamily: fontFamilies.GraphikMedium,
        fontWeight: 500,
        fontSize: '17px',
    },
});
const StyledRegularBox = styled(Box)({
    '& .MuiTypography-root, & li': {
        fontFamily: fontFamilies.GraphikRegular,
        fontWeight: 400,
        fontSize: '16px',
        color: '#222',
        lineHeight: '1.7em',
    },
    '& ul': {
        margin: 0,
    }
});

export function ExpandableAccordion({ content }: ExpandableAccordionProps) {
    const [expandedTopic, setExpandedTopic] = useState<number | null>(null);

    const handleToggle = (index: number) => {
        setExpandedTopic(expandedTopic === index ? null : index);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ width: '100%' }}>
                <Typography
                    variant='h3'
                    textAlign='left'
                    style={{
                        fontFamily: fontFamilies.GraphikSemibold,
                        fontWeight: 600,
                        fontSize: '40px',
                        color: '#002B5A',
                    }}>
                    {content.title}
                </Typography>
                <Typography
                    style={{
                        color: '#002B5A',
                        fontFamily: fontFamilies.GraphikRegular,
                        fontSize: '17px',
                        marginTop: '8px',
                        lineHeight: '1.6em',
                    }}
                    textAlign='left'>
                    {content.description}
                </Typography>
            </Box>
            <Box sx={{ marginTop: '28px'}}>
                {content.topics.map((topic, index) => (
                    <Box key={index} sx={{}}>
                        <StyledMediumBox
                            display='flex'
                            alignItems='center'
                            justifyContent='space-between'
                            sx={{
                                backgroundColor: expandedTopic === index ? '#002B5A' : '#F9F9F9',
                                marginBottom: expandedTopic === index ? '18px' : '24px',
                            }}
                            onClick={() => handleToggle(index)}>
                            <Typography variant='body1' sx={{ color: expandedTopic === index ? '#fff' : '#002B5A' }}>
                                {topic.title}
                            </Typography>
                            <IconButton sx={{ color: expandedTopic === index ? '#fff' : '#002B5A' }}>
                                {expandedTopic === index ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </IconButton>
                        </StyledMediumBox>

                        <Collapse in={expandedTopic === index}>
                            <StyledRegularBox sx={{
                                fontFamily: fontFamilies.GraphikRegular,
                                fontWeight: 400,
                                fontSize: '16px',
                                marginBottom: expandedTopic === index ?  '16px' : 0,
                                paddingLeft: '28px',
                            }}>
                                {typeof topic.content === 'string' ? (
                                    <Typography variant='body2' dangerouslySetInnerHTML={{ __html: topic.content }} />
                                ) : (
                                    topic.content
                                )}
                            </StyledRegularBox>
                        </Collapse>
                    </Box>
                ))}
            </Box>
        </Box>
    );
}

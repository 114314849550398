import { Toggle } from '@cfra-nextgen-frontend/shared/src/components/Toggle/ToggleVariant1';
interface BiggestConcernToggleProps {
    onRegionChange: (regions: string[]) => void;
}

export function BiggestConcernToggle({ onRegionChange }: BiggestConcernToggleProps) {
    const options = ['All', 'North America', 'Europe'];

    const regionMapping: { [key: string]: string[] } = {
        All: ['2', '3'],
        'North America': ['2'],
        Europe: ['3'],
    };

    const handleToggle = (index: number) => {
        const selectedRegion = options[index];
        onRegionChange(regionMapping[selectedRegion]);
    };

    return <Toggle options={options} onChange={handleToggle} />;
}

import { uniq } from "lodash";

export function joinArraysAndGetUniqueValues(objValue: any, srcValue: any) {
    if (Array.isArray(objValue)) {
        return uniq([...objValue, ...srcValue]);
    }
}

export function replaceOldArrayWithNewOne(objValue: any, srcValue: any) {
    if (Array.isArray(objValue)) {
        return objValue;
    }
}
import Tooltip from '@cfra-nextgen-frontend/shared/src/components/dataDisplay/Tooltip';
import { tooltipThemeV2 } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import { fontFamilies } from '@cfra-nextgen-frontend/shared/src/utils';
import { Box, ThemeProvider } from '@mui/material';
import { styled } from '@mui/material/styles';

const Badge = styled(Box)(({ theme }) => ({
    background: '#F66C61',
    borderRadius: '3px',
    fontSize: '10px',
    fontFamily: fontFamilies.GraphikSemibold,
    color: '#fff',
    width: '26px',
    height: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '1px',
}));

export function BadgeBiggestConcerns() {
    return (
        <Box>
            <ThemeProvider theme={tooltipThemeV2}>
                <Tooltip title='Company on the Biggest Concerns List'>
                    <Badge>BC</Badge>
                </Tooltip>
            </ThemeProvider>
        </Box>
    );
}

import '@cfra-nextgen-frontend/shared/src/components/AgGrid/GridThemeV2.scss';
import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import { SavedItemTypes } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/savedItem';
import { CreateSavedItemResponseTypes } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/savedScreens';
import { getFiltersReqBody } from '@cfra-nextgen-frontend/shared/src/utils/api';
import { getSavedItemsByIdConfig } from '@cfra-nextgen-frontend/shared/src/utils/userSavedItem';
import { isEqual } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { UseQueryResult } from 'react-query';

type UseWatchlistDataInputProps = {
    selectedWatchlist?: SavedItemTypes;
};

type UseWatchlistDataOutputProps = {
    getWatchlistValueQry: UseQueryResult<CreateSavedItemResponseTypes>;
    secTradingIdsFilter: ReturnType<typeof getFiltersReqBody>;
};

export function useWatchlistData({ selectedWatchlist }: UseWatchlistDataInputProps): UseWatchlistDataOutputProps {
    const [secTradingIdsFilter, setSecTradingIdsFilter] = useState<any>();

    const { sendSingleRequest } = useContext(ProjectSpecificResourcesContext);

    if (!sendSingleRequest) {
        throw new Error('sendSingleRequest is not defined');
    }

    const getWatchlistValueQry = sendSingleRequest<CreateSavedItemResponseTypes>(
        {
            savedItems: selectedWatchlist?.id || 0,
            includeValues: true,
            config: { enabled: selectedWatchlist?.id && selectedWatchlist?.id > 0 ? true : false },
        },
        getSavedItemsByIdConfig,
    );

    useEffect(() => {
        if (!selectedWatchlist) {
            setSecTradingIdsFilter(undefined);
        }
    }, [selectedWatchlist])

    useEffect(() => {
        if (
            getWatchlistValueQry &&
            !getWatchlistValueQry.isRefetching &&
            !getWatchlistValueQry.isError &&
            getWatchlistValueQry?.data?.data
        ) {
            const value = getWatchlistValueQry.data.data.value as any;
            if (value?.values?.length > 0) {
                const cfraSecTradingIds = value.values
                    .filter((p: any) => p.cfra_security_trading_id)
                    .map(
                        (p: any) =>
                            `company_security.security_trading.cfra_security_trading_id_|_${p.cfra_security_trading_id}`,
                    );

                // If security trading id is not present then only fetch company id
                const cfraCompanyIds = value.values
                    .filter((p: any) => p.cfra_company_id && !p.cfra_security_trading_id)
                    .map((p: any) => `company_security.company.cfra_company_id_|_${p.cfra_company_id}`);

                const filterObj = {
                    'company_security.security_trading.cfra_security_trading_company_id': {
                        values: [...cfraSecTradingIds, ...cfraCompanyIds],
                    },
                };
                if (
                    (cfraSecTradingIds.length > 0 || cfraCompanyIds.length > 0) &&
                    !isEqual(secTradingIdsFilter, filterObj)
                ) {
                    setSecTradingIdsFilter(filterObj);
                }
            }
        }
    }, [getWatchlistValueQry, secTradingIdsFilter]);
    return {
        secTradingIdsFilter,
        getWatchlistValueQry,
    };
}

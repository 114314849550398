import { ETFDownloadButton, ETFMenu } from '@cfra-nextgen-frontend/shared';
import { CSSProperties } from 'react';

export const actionMenuItemStyles: Record<string, React.CSSProperties> = {
    menuItemIcon: {
        marginLeft: '10px',
        marginRight: '10px',
    },
    menuItemText: {
        display: 'block',
        whiteSpace: 'normal',
        color: '#3C3C3C',
        lineHeight: '32px',
    },
};

export function WatchlistActionMenu(props: { menuItems: any; containerSx?: CSSProperties }) {
    return (
        <div style={{ marginLeft: 20, paddingTop: 10, ...props.containerSx }}>
            <ETFMenu.ETFMenu
                menuItemStyles={{
                    fontSize: '13px',
                    fontWeight: 400,
                    fontFamily: 'GraphikRegular',
                    padding: '6px',
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: 0,
                    border: 0,
                    width: '100%',
                    '&:hover': {
                        border: 0,
                        backgroundColor: '#E0E0E0',
                    },
                }}
                menuItems={props.menuItems}
                paperProps={{
                    width: '124px',
                }}
                onHoverItemStyles={{
                    backgroundColor: '#E0E0E0',
                }}
                addBorderBetweenItems={false}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}>
                <ETFDownloadButton tooltipText='Watchlist Actions' />
            </ETFMenu.ETFMenu>
        </div>
    );
}

import { ETFCard } from '@cfra-nextgen-frontend/shared/src/components/ETFCard';
import { AgGridExportProps } from '@cfra-nextgen-frontend/shared/src/components/excelExport/export';
import { Grid } from '@cfra-nextgen-frontend/shared/src/components/layout';
import { Title } from '@cfra-nextgen-frontend/shared/src/components/Typography/StyledTitles';
import { SxProps } from '@mui/material/styles';
import { AgGridReact } from 'ag-grid-react';
import { exportAgGrid } from 'components/excelExport/export';
import { useRef } from 'react';
import { ExportButton } from './ExportButton';
import { useWatchlistActions } from './hooks/useWatchlistActions';
import { WatchlistCompanies, WatchlistCompaniesProps } from './WatchlistCompanies';

type ManageUserWatchlistProps = {
    title: string;
    watchlistCompaniesProps?: WatchlistCompaniesProps;
    containerStyles?: SxProps;
    showExport?: boolean;
    showUpdateWatchlist?: boolean;
    exportProps?: AgGridExportProps;
};

export function ManageUserWatchlist({
    title,
    watchlistCompaniesProps,
    containerStyles,
    showExport = false,
    showUpdateWatchlist = false,
    exportProps,
}: ManageUserWatchlistProps) {
    const {
        createWatchlistButtonJsx,
        watchlistSelectJsx,
        operationsModalsJsx,
        selectedWatchlist,
        watchlistOperationsMenuIconJsx,
        addItemsSearchInputBoxJsx,
    } = useWatchlistActions({});
    const gridRef = useRef<AgGridReact>(null);

    const agGridExportProps: AgGridExportProps = {
        ...{
            fileName: `CFRA ${selectedWatchlist?.name || 'Institutional Watchlist'}`,
            sheetName: 'Data Details',
            metadata: {
                cardName: selectedWatchlist?.name || 'Institutional Watchlist',
            },
            autoSizeColumns: true,
        },
        ...exportProps,
    };

    const overlayExportCallback = () => exportAgGrid(gridRef, agGridExportProps);

    return (
        <ETFCard containerStyles={containerStyles}>
            <Grid item sm={12} display={'flex'}>
                <Title>{title}</Title>
                {showExport && selectedWatchlist && (
                    <Grid display={'flex'} width={'100%'} justifyContent={'flex-end'} alignItems={'center'}>
                        <ExportButton onClick={overlayExportCallback} />
                    </Grid>
                )}
            </Grid>
            <Grid item sm={12} display={'flex'} flexDirection={'row'}>
                {createWatchlistButtonJsx}
                <div style={{ marginLeft: 10 }}>{watchlistSelectJsx}</div>
                {selectedWatchlist && (
                    <>
                        {watchlistOperationsMenuIconJsx}
                        <Grid item display={'flex'} width={'100%'} justifyContent={'flex-end'}>
                            {showUpdateWatchlist && addItemsSearchInputBoxJsx}
                        </Grid>
                    </>
                )}
                {operationsModalsJsx}
            </Grid>
            <Grid item marginTop={2} sm={12}>
                <WatchlistCompanies
                    gridRef={gridRef}
                    selectedWatchlist={selectedWatchlist}
                    {...watchlistCompaniesProps}
                />
            </Grid>
        </ETFCard>
    );
}

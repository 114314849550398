import { pillsTooltipTheme, PillVariant1 } from '@cfra-nextgen-frontend/shared/src/components/Pill/PillVariant1';
import { tooltipThemeV3 } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import { useMakeIndependent } from '@cfra-nextgen-frontend/shared/src/hooks/useMakeIndependent';
import { Box, createTheme, Grid, ThemeProvider, Tooltip, Typography } from '@mui/material';
import React, { CSSProperties, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function Comma() {
    return <>,</>;
}

type ItemProps = {
    text: string;
    onClick?: () => void;
    showComma?: boolean;
    href: string;
    linkStyle?: CSSProperties;
    nearTextRightSlot?: React.ReactNode;
};

function Item({ text, onClick, showComma = true, href, linkStyle, nearTextRightSlot }: ItemProps) {
    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}>
                <a onClick={onClick} href={href} target={'_blank'} rel='noreferrer' style={linkStyle}>
                    {text}
                </a>
                {nearTextRightSlot}
            </Box>

            {showComma && (
                <Box>
                    <Typography sx={linkStyle}>
                        <Comma />
                        &nbsp;
                    </Typography>
                </Box>
            )}
        </>
    );
}

const linksTooltipTheme = createTheme(tooltipThemeV3, {
    components: {
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    padding: '9px 18px 9px 18px',
                    marginTop: '0px !important',
                },
            },
        },
    },
});

export type LinksRowProps = {
    linksConfig: Array<{
        text: string;
        link: string;
        nearTextRightSlot: React.ReactNode;
    }>;
    tooltipThreshold?: number;
    tooltipContent?: string;
    toolTipContentComponent?: string;
    onCrossThresholdText?: string;
    linkStyle?: CSSProperties;
};

export function LinksRow({
    linksConfig,
    tooltipThreshold = linksConfig.length,
    toolTipContentComponent,
    onCrossThresholdText,
    linkStyle,
}: LinksRowProps) {
    const navigate = useNavigate();
    const [hovered, setHovered] = useState(false);

    const { independentValue: navigateRef } = useMakeIndependent({
        valueGetter: () => (url: string) => navigate(url),
        defaultValue: () => {},
    });

    const getAllItemsJsx = useCallback(
        (showCommas: boolean) => {
            return (
                <>
                    {React.Children.toArray(
                        linksConfig.map((linkConfig, index) => (
                            <Item
                                key={`${index}_${showCommas}`}
                                text={linkConfig.text}
                                href={linkConfig.link}
                                onClick={() => {}} // TODO: handle analytics here
                                showComma={showCommas && index !== linksConfig.length - 1}
                                linkStyle={linkStyle}
                                nearTextRightSlot={linkConfig.nearTextRightSlot}
                            />
                        )),
                    )}
                </>
            );
        },
        [linksConfig, linkStyle],
    );

    const showTooltip = linksConfig.length > tooltipThreshold;

    const tooltipContent = useMemo(() => {
        if (!showTooltip) {
            return;
        }

        switch (toolTipContentComponent) {
            case 'links-column':
                return {
                    tooltipContent: getAllItemsJsx(false),
                    tooltipTheme: linksTooltipTheme,
                };
            case 'pills-row':
                return {
                    tooltipContent: (
                        <Grid container gap={'10px'} rowGap={'5px'}>
                            {React.Children.toArray(
                                linksConfig.map((linkConfig, index) => {
                                    return (
                                        <PillVariant1
                                            key={index}
                                            content={linkConfig.text}
                                            onClick={() => navigateRef?.current(linkConfig.link)}
                                        />
                                    );
                                }),
                            )}
                        </Grid>
                    ),
                    tooltipTheme: pillsTooltipTheme,
                };
            default:
                throw new Error(`The toolTipContentComponent ${toolTipContentComponent} is not supported by LinksRow.`);
        }
    }, [showTooltip, toolTipContentComponent, getAllItemsJsx, linksConfig, navigateRef]);

    return (
        <Box>
            {showTooltip ? (
                <ThemeProvider theme={tooltipContent!.tooltipTheme}>
                    <Box
                        onMouseEnter={() => setHovered(true)}
                        onMouseLeave={() => setHovered(false)}
                        sx={{
                            display: 'inline-flex',
                        }}>
                        <Tooltip
                            title={tooltipContent?.tooltipContent}
                            placement={'bottom-start'}
                            onOpen={() => setHovered(true)}
                            onClose={() => setHovered(false)}>
                            <Box>
                                <Typography
                                    sx={{
                                        lineHeight: 1,
                                        ...linkStyle,
                                        textDecoration: hovered ? 'underline' : undefined,
                                    }}>
                                    {onCrossThresholdText}
                                </Typography>
                            </Box>
                        </Tooltip>
                    </Box>
                </ThemeProvider>
            ) : (
                <Box
                    sx={{
                        display: 'inline-flex',
                    }}>
                    {getAllItemsJsx(true)}
                </Box>
            )}
        </Box>
    );
}

import { ItemHeader } from '@cfra-nextgen-frontend/shared/src/components/ETFCard';
import { fontFamilies } from '@cfra-nextgen-frontend/shared/src/utils';
import { styled } from '@mui/material/styles';

export const Title = styled(ItemHeader)(({ theme }) => ({
    paddingBottom: '10px',
    color: '#002B5A',
    fontFamily: fontFamilies.GraphikMedium,
    fontSize: '18px',
    fontHeight: 500,
    lineHeight: '28px',
    boxShadow: 'none',
}));

import { useState } from 'react';
import { Box, Button, createTheme, ThemeProvider } from '@mui/material';
import { fontFamilies } from '@cfra-nextgen-frontend/shared/src/utils/fonts';

export type ToggleProps = {
    defaultSelectionIndex?: number;
    options: Array<string>;
    onChange: (index: number) => void;
};

const ToggleThemeVariant1 = createTheme({
    components: {
        MuiButton: {
            variants: [
                {
                    props: { variant: 'concerns' },
                    style: {
                        backgroundColor: '#F66C61',
                        fontFamily: fontFamilies.GraphikSemibold,
                        fontSize: '10px',
                        lineHeight: '28px',
                        color: '#FFF',
                        fontWeight: 600,
                        height: '24px',
                        width: 'auto',
                        '&:hover': {
                            backgroundColor: '#F66C61',
                        },
                    },
                },
            ],
        },
    },
});

export function Toggle({ defaultSelectionIndex, options, onChange }: ToggleProps) {
    const [selectedOption, setSelectedOption] = useState(defaultSelectionIndex || 0);

    const handleToggle = (index: number) => {
        setSelectedOption(index);
        onChange(index);
    };

    return (
        <ThemeProvider theme={ToggleThemeVariant1}>
            <Box
                display='flex'
                alignItems='center'
                justifyContent='center'
                sx={{ background: '#E4E8F4', borderRadius: '30px' }}>
                {options.map((option, index) => (
                    <Button
                        key={index}
                        variant={selectedOption === index ? 'contained' : 'outlined'}
                        onClick={() => handleToggle(index)}
                        sx={{
                            padding: '10px 20px',
                            borderRadius: '20px',
                            background: selectedOption === index ? '#ffffff' : '#E4E8F4',
                            color: selectedOption === index ? '#007AB9' : '#666666',
                            fontFamily: fontFamilies.GraphikSemibold,
                            '&:hover': {
                                background: '#ffffff',
                                border: 'none',
                                boxShadow:
                                    '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
                            },
                            margin: 0,
                            border: 'none',
                            textTransform: 'none',
                        }}>
                        <span style={{ fontSize: '13.5px' }}>{option}</span>
                    </Button>
                ))}
            </Box>
        </ThemeProvider>
    );
}

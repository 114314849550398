import { ReactComponent as CloseIcon } from '@cfra-nextgen-frontend/shared/src/assets/images/CloseIcon_2.svg';
import { ReactComponent as SearchIcon2 } from '@cfra-nextgen-frontend/shared/src/assets/images/SearchIcon_2.svg';
import { CustomSvgIcon } from '@cfra-nextgen-frontend/shared/src/components/Icon/CustomSvgIcon';
import { Box, IconButton, InputAdornment, Stack, ThemeProvider } from '@mui/material';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import { themeVariant2 } from './theme';
import { SearchInputProps } from './types';

export function SearchInputVariant2({
    afterInputSlot,
    clearButtonCallback,
    onChange,
    showInput,
    textFieldRefCallback,
    wrapperRefCallback,
    onKeyDown,
    onClick,
}: SearchInputProps) {
    // uses to show / hide clear button
    const [inputValue, setInputValue] = useState('');
    // uses to clear input value
    const [inputNode, setInputNode] = useState<HTMLInputElement | null>(null);

    return (
        <ThemeProvider theme={themeVariant2}>
            <Stack
                ref={wrapperRefCallback}
                direction='row'
                sx={{
                    width: '100%',
                    height: '44px',
                    maxWidth: '653px',
                    display: showInput ? 'flex' : 'none',
                    flexDirection: 'row',
                    alignItems: 'center',
                    position: 'relative',
                }}>
                <Box
                    sx={{
                        backgroundColor: '#F3F5FC',
                        width: '49px',
                        height: '44px',
                        borderRadius: '50%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginRight: '23px',
                    }}>
                    <CustomSvgIcon
                        component={SearchIcon2}
                        viewBox='0 0 18 18'
                        sx={{
                            width: '18px',
                            fill: '#3C3C3C',
                        }}
                    />
                </Box>
                <TextField
                    inputRef={(node) => {
                        textFieldRefCallback?.(node);
                        setInputNode(node);
                    }}
                    placeholder='Search by ticker, keyword or phrase.'
                    variant='standard'
                    autoComplete='off'
                    InputProps={{
                        endAdornment: (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    gap: '5px',
                                    visibility: inputValue ? 'visible' : 'hidden',
                                    opacity: inputValue ? 1 : 0,
                                    transition: 'opacity 0.1s ease-out, visibility 0.1s ease-out',
                                }}>
                                <InputAdornment position='end'>
                                    <IconButton
                                        sx={{ padding: 0 }}
                                        onClick={() => {
                                            clearButtonCallback?.();
                                            setInputValue('');

                                            if (!inputNode) {
                                                return;
                                            }

                                            inputNode.value = '';
                                        }}>
                                        <CustomSvgIcon
                                            component={CloseIcon}
                                            viewBox='0 0 18 18'
                                            sx={{
                                                width: '18px',
                                                fill: '#AAAAAA',
                                                '&:hover': {
                                                    fill: '#007AB9',
                                                },
                                            }}
                                        />
                                    </IconButton>
                                </InputAdornment>
                            </Box>
                        ),
                    }}
                    onClick={onClick}
                    onChange={(event) => {
                        setInputValue(event.target.value);
                        onChange?.(event);
                    }}
                    onKeyDown={onKeyDown}
                />
                {afterInputSlot}
            </Stack>
        </ThemeProvider>
    );
}

import { Box, MenuItem, styled } from '@mui/material';
import { PillProps } from './Pill';

type PillMoreProps = {
    label: string;
    count?: number;
    itemKey: number | string;
    field: any;
    onChipClick: () => void;
    imageMap: PillProps['imageMap'];
};

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    fontSize: '13px',
    fontWeight: 400,
    fontFamily: 'GraphikRegular',
    padding: '6px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 0,
    width: '100%',
    '&:hover': {
        backgroundColor: '#E0E0E0',
    },
}));

export function PillMore(props: PillMoreProps) {
    const Icon = props.imageMap[props.itemKey] || props.imageMap[props.label];

    return (
        <StyledMenuItem onClick={props.onChipClick}>
            {Icon && (
                <Box sx={{ marginRight: '10px' }}>
                    <Icon />
                </Box>
            )}
            <span
                style={{
                    display: 'block',
                    overflowWrap: 'break-word',
                    wordBreak: 'break-word',
                    whiteSpace: 'normal',
                    color: '#222222',
                    lineHeight: '2.1em',
                }}>
                {props.label}
            </span>
            <span style={{ color: '#007BB8', marginLeft: '6px', lineHeight: '1.8em', fontFamily: 'GraphikMedium' }}>
                {props.count}
            </span>
        </StyledMenuItem>
    );
}

import { Grid } from '@cfra-nextgen-frontend/shared/src/components/layout';
import { SendSingleRequest } from '@cfra-nextgen-frontend/shared/src/components/Screener/api/screener';
import {
    useFiltersForm,
    UseFiltersFormExternalInputProps,
    UseFiltersFormInputProps,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/forms/hooks/useFiltersForm';
import { Box } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { homePageTheme } from 'components/themes/theme';
import { horizontalPaddingInPx, maxPageWidthInPxIncludingPadding } from 'utils/lookAndFeel';

export function FiltersSection(
    props: UseFiltersFormExternalInputProps & {
        getFiltersJsx: UseFiltersFormInputProps['getFiltersJsx'];
        filtersRequestParams: Parameters<SendSingleRequest>;
    },
) {
    const { filtersFormJsx, filtersChipPanelJsx } = useFiltersForm({
        ...props,
        chipStyles: {
            containerSx: { padding: '0' },
            onChipItemsExistSxProps: { marginBottom: '17px' },
        },
        skeletonContainerSx: { padding: '0', marginBottom: '24px' },
        formStyle: { width: '100%' },
    });

    return (
        <Box
            component='section'
            sx={{
                backgroundColor: '#FFFFFF',
                maxWidth: '100%',
                width: '100%',
                margin: '0 auto',
                marginBottom: '36px',
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
            }}>
            <Box
                sx={{
                    padding: `24px ${horizontalPaddingInPx}px 0px ${horizontalPaddingInPx}px`,
                    width: '100%',
                    maxWidth: maxPageWidthInPxIncludingPadding,
                }}>
                <ThemeProvider theme={homePageTheme}>
                    <Grid sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', width: '100%' }}>
                        {filtersFormJsx}
                        <Box sx={{ width: 'calc(100% - 171px)' }}>{filtersChipPanelJsx}</Box>
                    </Grid>
                </ThemeProvider>
            </Box>
        </Box>
    );
}

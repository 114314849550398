import { ReactComponent as CloseIcon } from '@cfra-nextgen-frontend/shared/src/assets/images/CloseIcon_2.svg';
import { ReactComponent as SearchIcon2 } from '@cfra-nextgen-frontend/shared/src/assets/images/SearchIcon_2.svg';
import { CustomSvgIcon } from '@cfra-nextgen-frontend/shared/src/components/Icon/CustomSvgIcon';
import { Box, createTheme, IconButton, InputAdornment, Stack, ThemeProvider } from '@mui/material';
import TextField from '@mui/material/TextField';
import { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { themeVariant1 } from './theme';
import { SearchInputProps } from './types';

export type SearchInputVariant1Ref = {
    clearSearch: () => void;
};

type SearchInputVariant1Props = SearchInputProps & {
    showSearchIconOnlyWhenEmptyInput?: boolean;
    showClearButtonOnlyWhenNonEmptyInput?: boolean;
} & {
    showSearchIconOnlyWhenEmptyInput?: boolean;
    showClearButtonOnlyWhenNonEmptyInput?: boolean;
};

export const SearchInputVariant1 = forwardRef<SearchInputVariant1Ref, SearchInputVariant1Props>(
    (
        {
            clearButtonCallback,
            onChange,
            showInput,
            textFieldRefCallback,
            wrapperRefCallback,
            onKeyDown,
            onClick,
            placeholder,
            hideClearButton,
            customTheme,
            showSearchIconOnlyWhenEmptyInput,
            showClearButtonOnlyWhenNonEmptyInput,
        },
        ref,
    ) => {
        const [inputValue, setInputValue] = useState('');
        const [inputNode, setInputNode] = useState<HTMLInputElement | null>(null);

        const theme = createTheme(themeVariant1, customTheme || {});
        const showSearchIcon = showSearchIconOnlyWhenEmptyInput !== undefined ? !Boolean(inputValue) : true;
        const showClearButton = showClearButtonOnlyWhenNonEmptyInput !== undefined ? Boolean(inputValue) : true;

        const clearSearch = useCallback(() => {
            setInputValue('');
            clearButtonCallback?.();
            if (inputNode) {
                inputNode.value = '';
            }
        }, [clearButtonCallback, inputNode]);

        useImperativeHandle(ref, () => ({
            clearSearch,
        }));

        return (
            <ThemeProvider theme={theme}>
                <Stack
                    ref={wrapperRefCallback}
                    direction='row'
                    sx={{
                        display: showInput ? 'flex' : 'none',
                    }}>
                    <TextField
                        inputRef={(node) => {
                            setInputNode(node);
                            textFieldRefCallback?.(node);
                        }}
                        placeholder={placeholder || 'Search CFRA content'}
                        variant='standard'
                        autoComplete='off'
                        InputProps={{
                            endAdornment: (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        gap: '5px',
                                    }}>
                                    {showSearchIcon && (
                                        <IconButton sx={{ marginLeft: '8px', padding: '0' }}>
                                            <CustomSvgIcon
                                                component={SearchIcon2}
                                                viewBox='0 0 18 18'
                                                sx={{
                                                    width: '18px',
                                                    fill: '#007AB9',
                                                }}
                                            />
                                        </IconButton>
                                    )}
                                    {!hideClearButton && showClearButton && (
                                        <InputAdornment position='end'>
                                            <IconButton sx={{ padding: 0 }} onClick={clearSearch}>
                                                <CustomSvgIcon
                                                    component={CloseIcon}
                                                    viewBox='0 0 18 18'
                                                    sx={{
                                                        width: '18px',
                                                        fill: '#AAAAAA',
                                                        '&:hover': {
                                                            fill: '#007AB9',
                                                        },
                                                    }}
                                                />
                                            </IconButton>
                                        </InputAdornment>
                                    )}
                                </Box>
                            ),
                        }}
                        onClick={onClick}
                        onChange={(e) => {
                            setInputValue(e.target.value);
                            onChange?.(e);
                        }}
                        onKeyDown={onKeyDown}
                    />
                </Stack>
            </ThemeProvider>
        );
    },
);

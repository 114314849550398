import { SxProps } from '@mui/material';
import { horizontalPaddingInSu, maxPageWidthInPxIncludingPadding } from 'utils/lookAndFeel';

export const pageWithComponentInHeaderStyleProps: Record<string, SxProps> = {
    pageContainerSx: {
        maxWidth: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    tabContainerSx: {
        paddingTop: '12px',
        paddingBottom: horizontalPaddingInSu,
        paddingLeft: 0,
        paddingRight: 0,
        backgroundColor: 'unset',
        width: '100%',
    },
    headerSx: {
        position: 'static',
    },
    headerContainerSx: {
        maxWidth: maxPageWidthInPxIncludingPadding,
        alignContent: 'center',
        paddingLeft: horizontalPaddingInSu,
        paddingRight: horizontalPaddingInSu,
    },
};

import React from 'react';
import { Box, Typography, List, ListItem, ListItemText, styled } from '@mui/material';
import { fontFamilies } from '@cfra-nextgen-frontend/shared/src/utils';

interface Feature {
    category: string;
    items: string[];
}

interface FeatureListProps {
    features: Feature[];
}

const StyledListItemText = styled(ListItemText)({
    margin: 0,
    '& .MuiTypography-root': {
        padding: 0, 
        lineHeight: '1.5em', 
        fontSize: '16px', 
        fontFamily: fontFamilies.GraphikRegular, 
        '&::before': {
            content: '"•"',
            position: 'absolute',
            left: 0,
            color: '#222',
            fontSize: '18px',
        },
    },
    '& .MuiListItem-root': {
        paddingLeft: '13px',
    }
});

export const BespokeFeatureList: React.FC<FeatureListProps> = ({ features }) => {
    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '35px' }}>
            {features.map((feature, index) => (
                <Box
                    key={index}
                    sx={{
                        maxWidth: '377px',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        borderRadius: '20px',
                        border: '1px solid #C7C3C3',
                        padding: '17px 24px',
                    }}>
                    <Typography
                        variant='h6'
                        sx={{
                            fontFamily: fontFamilies.GraphikSemibold,
                            fontWeight: 600,
                            fontSize: '18px',
                            color: '#002B5A',
                        }}>
                        {feature.category}
                    </Typography>
                    <List sx={{
                        padding: 0,
                        margin: '13px 0 0',
                    }}>
                        {feature.items.map((item, idx) => (
                            <React.Fragment key={idx}>
                                <ListItem>
                                    <StyledListItemText primary={`${item}`}/>
                                </ListItem>
                            </React.Fragment>
                        ))}
                    </List>
                </Box>
            ))}
        </Box>
    );
};

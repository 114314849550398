import { Heading, Image, View, Text } from '@aws-amplify/ui-react';
import CFRALogo from '../../../assets/images/cfra_logo.svg';
import CFRAWhiteLogo from '../../../assets/images/CFRA White Logo.svg';
import { useMediaQuery } from '@mui/material';

interface InstitutionalLogoProps {
    heading?: string;
    description?: string;
}

export const InstitutionalLogo = ({ heading = 'Institutional', description=''}: InstitutionalLogoProps) => {
    const isMobile = useMediaQuery('(max-width:999.5px)');
    return (
        <View textAlign='center'>
            <Image alt='Company logo' src={isMobile ? CFRAWhiteLogo : CFRALogo} style={{height: isMobile ? '36px' : '28px'}} />
            <Heading 
                level={1} 
                style={{ marginBottom: '5px', fontFamily: 'GraphikBold', color: isMobile ? '#fff' : '#3B99A7', fontSize: '52px', marginTop: '10px', letterSpacing: '-0.035em' }}
            >
                {heading}
            </Heading>
            <Text 
                style={{ marginBottom: '1rem', fontFamily: 'GraphikRegular', fontSize: '15px', lineHeight: '1.7em', color: isMobile ? '#fff' : '#222222'}}
            >
               {description}
            </Text>
        </View>
    );
};

import '@cfra-nextgen-frontend/shared/src/components/AgGrid/GridThemeV2.scss';
import { ClearButton } from '@cfra-nextgen-frontend/shared/src/components/ETFButton/ClearButton';
import { autocompleteThemeVariant1 } from '@cfra-nextgen-frontend/shared/src/components/Form/themes/autocomplete';
import { UseFiltersFormInputProps } from '@cfra-nextgen-frontend/shared/src/components/Screener/forms/hooks/useFiltersForm';
import { ScreenerFormVirtualizeAutocomplete } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerFormElements/ScreenerFormVirtualizeAutocomplete';
import { Toggle, ToggleProps } from '@cfra-nextgen-frontend/shared/src/components/Toggle/ToggleVariant1';
import { Box, createTheme, ThemeProvider } from '@mui/material';
import { PageTheme } from 'components/themes/theme';
import {
    SearchInputVariant1,
    SearchInputVariant1Ref,
} from '@cfra-nextgen-frontend/shared/src/components/SearchInput/SearchInputVariant1';
import { SearchInputProps } from '@cfra-nextgen-frontend/shared/src/components/SearchInput/types';

const customTheme = createTheme({
    components: {
        MuiStack: {
            styleOverrides: {
                root: {
                    width: '290px',
                    border: '1px solid rgba(0, 0, 0, 0.23)',
                    borderRadius: '10px',
                    background: '#FFF',
                    height: '48px',
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    paddingTop: '0px !important',
                    paddingBottom: '0px !important',
                    height: '42px',
                },
            },
        },
    },
});

export const getFiltersJsx = ({
    toggleProps,
    searchInputProps,
}: {
    toggleProps: ToggleProps;
    searchInputProps: SearchInputProps & { ref: React.RefObject<SearchInputVariant1Ref> };
}) =>
    (({ submitHandler, control, filtersData, getValues, setValue, validate, onChipClearAllClick }) => {
        return (
            <>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'left',
                        gap: '12px',
                        paddingBottom: '24px',
                    }}>
                    <ThemeProvider theme={PageTheme}>
                        <Toggle {...toggleProps} />
                    </ThemeProvider>
                    <ScreenerFormVirtualizeAutocomplete
                        defaultInputLabel={'Scores'}
                        placeholder={'Scores'}
                        useFormLabelWithContainer={false}
                        control={control}
                        filtersData={filtersData}
                        filterMetadataKey={'frs_cash_flow_score_drivers.decile'}
                        submitHandler={submitHandler}
                        getValues={getValues}
                        setValue={setValue}
                        validate={validate}
                        parentSectionKey={'root'}
                        hide={false}
                        theme={createTheme(autocompleteThemeVariant1, {
                            components: {
                                MuiAutocomplete: {
                                    styleOverrides: {
                                        root: {
                                            width: '112px',
                                        },
                                    },
                                },
                            },
                        })}
                        autocompleteSxProps={{
                            minWidth: '112px',
                        }}
                        ignoreFocus={true}
                    />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left', gap: '12px' }}>
                    <ScreenerFormVirtualizeAutocomplete
                        defaultInputLabel={'Industry'}
                        placeholder={'Industry'}
                        useFormLabelWithContainer={false}
                        control={control}
                        filtersData={filtersData}
                        filterMetadataKey={'company_security.company_sector._lookup_gics_ind_grp_lid.key'}
                        submitHandler={submitHandler}
                        getValues={getValues}
                        setValue={setValue}
                        validate={validate}
                        parentSectionKey={'root'}
                        hide={false}
                        theme={createTheme(autocompleteThemeVariant1, {
                            components: {
                                MuiAutocomplete: {
                                    styleOverrides: {
                                        root: {
                                            width: '189px',
                                        },
                                    },
                                },
                            },
                        })}
                        ignoreFocus={true}
                    />
                    <ScreenerFormVirtualizeAutocomplete
                        defaultInputLabel={'Region'}
                        placeholder={'Region'}
                        useFormLabelWithContainer={false}
                        control={control}
                        filtersData={filtersData}
                        filterMetadataKey={'company_security.company.region_country_lid'}
                        submitHandler={submitHandler}
                        getValues={getValues}
                        setValue={setValue}
                        validate={validate}
                        parentSectionKey={'root'}
                        hide={false}
                        theme={createTheme(autocompleteThemeVariant1, {
                            components: {
                                MuiAutocomplete: {
                                    styleOverrides: {
                                        root: {
                                            width: '222px',
                                        },
                                    },
                                },
                            },
                        })}
                        ignoreFocus={true}
                    />
                    <ThemeProvider theme={createTheme()}>
                        <SearchInputVariant1
                            customTheme={createTheme(customTheme, {
                                components: {
                                    MuiStack: {
                                        styleOverrides: {
                                            root: {
                                                width: '251px',
                                                paddingLeft: '17px',
                                            },
                                        },
                                    },
                                    MuiInputBase: {
                                        styleOverrides: {
                                            input: {
                                                color: '#3C3C3C',
                                                '::placeholder': {
                                                    opacity: 1,
                                                },
                                            },
                                        },
                                    },
                                },
                            })}
                            showInput
                            showClearButtonOnlyWhenNonEmptyInput
                            showSearchIconOnlyWhenEmptyInput
                            {...searchInputProps}
                        />
                    </ThemeProvider>
                    <ClearButton onClick={onChipClearAllClick} />
                </Box>
            </>
        );
    }) as UseFiltersFormInputProps['getFiltersJsx'];

import { Layout } from '@cfra-nextgen-frontend/shared';
import remove from '@cfra-nextgen-frontend/shared/src/assets/icons/remove.svg';
import rename from '@cfra-nextgen-frontend/shared/src/assets/icons/rename.svg';
import { StyledAvatar } from '@cfra-nextgen-frontend/shared/src/components/Avatar/Avatar';
import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import { DeleteSavedItemModal } from '@cfra-nextgen-frontend/shared/src/components/SavedItem/DeleteSavedItemModal';
import { RenameSavedItemModal } from '@cfra-nextgen-frontend/shared/src/components/SavedItem/RenameSavedItemModal';
import { SavedItemTypes } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/savedItem';
import {
    CreateSavedItemResponseTypes,
    CreateSavedItemTypes,
    SavedItemListResponseTypes,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/types/savedScreens';
import { ScreenerResearchCompanyData } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { PreferenceType, UserPreferences } from '@cfra-nextgen-frontend/shared/src/types/userPreferences';
import { UserSavedItemsLookupID } from '@cfra-nextgen-frontend/shared/src/utils';
import {
    getSavedItemsByIdConfig,
    getSavedItemsByTypeConfig,
} from '@cfra-nextgen-frontend/shared/src/utils/userSavedItem';
import { Box } from '@mui/material';
import {
    CompanySearchModalRef,
    NewWatchlistCompanySearchModal,
} from 'components/watchlists/newWatchlist/CompanySearchModal';
import { NewWatchlistModal, NewWatchlistRef } from 'components/watchlists/newWatchlist/Modal';
import { getQueryConfig } from 'features/companyProfile/api/company';
import _, { cloneDeep, isEqual } from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { UseQueryResult } from 'react-query';
import { WatchlistDropdown } from './WatchlistDropdown';
import { actionMenuItemStyles, WatchlistActionMenu } from 'components/watchlists/WatchlistActionMenu';

export type WatchlistCompanyIds = { cfraSecurityTradingId: string[]; cfraCompanyId: string[] };
export type WatchlistCompanyId = { cfra_company_id?: string; cfra_security_trading_id?: string };

type ManageWatchlistProps = {
    userPreferences: UserPreferences;
    setPreferences: (t: PreferenceType, v: any) => void;
    selectedWatchlistId?: number;
    watchlistCompanyIds?: WatchlistCompanyIds;
    setWatchlistCompanyIds: React.Dispatch<React.SetStateAction<WatchlistCompanyIds | undefined>>;
    setIndResearchGics: React.Dispatch<React.SetStateAction<string[] | undefined>>;
};

export const initialWatchlistCompanyIds = { cfraSecurityTradingId: [], cfraCompanyId: [] };

const cfraSecurityTradingField = 'company_security.security_trading.cfra_security_trading_id';
const cfraCompanyIdField = 'company_security.company.cfra_company_id';

const renderMenuItem = (name: string, icon: string) => (
    <Box display='flex' alignItems='center'>
        <StyledAvatar src={icon} style={actionMenuItemStyles.menuItemIcon} />
        <Box component='span' sx={actionMenuItemStyles.menuItemText}>
            {name}
        </Box>
    </Box>
);

export const ManageWatchlist = (props: ManageWatchlistProps) => {
    const { userPreferences, watchlistCompanyIds, setPreferences, setWatchlistCompanyIds, setIndResearchGics } = props;
    const { sendSingleRequest } = useContext(ProjectSpecificResourcesContext);

    const newWatchlistModalRef = useRef<NewWatchlistRef>(null);
    const companySearchRef = useRef<CompanySearchModalRef>(null);

    const [showCreateModal, setShowCreateModal] = useState(false);

    const [userWatchlist, setUserWatchlist] = useState<SavedItemTypes[]>([]);
    const [selectedWatchlist, setSelectedWatchlist] = useState<SavedItemTypes>();

    const [renameWatchlist, setRenameWatchlist] = useState<SavedItemTypes>();
    const [deleteWatchlist, setDeleteWatchlist] = useState<SavedItemTypes>();

    if (!sendSingleRequest) {
        throw new Error('sendSingleRequest is not defined');
    }

    const setDefaultFilterData = useCallback(() => {
        setWatchlistCompanyIds(initialWatchlistCompanyIds);
        setIndResearchGics([]);
    }, [setIndResearchGics, setWatchlistCompanyIds]);

    const unsetFilterData = useCallback(() => {
        setIndResearchGics(undefined);
        setWatchlistCompanyIds(undefined);
    }, [setIndResearchGics, setWatchlistCompanyIds]);

    const getAllWatchlistQry = sendSingleRequest<SavedItemListResponseTypes>(
        { types: [UserSavedItemsLookupID.InstitutionalWatchlist] },
        getSavedItemsByTypeConfig,
    );

    useEffect(() => {
        if (
            !getAllWatchlistQry.isLoading &&
            !getAllWatchlistQry.isRefetching &&
            !getAllWatchlistQry.isError &&
            getAllWatchlistQry.data?.data
        ) {
            const _userWatchlist = getAllWatchlistQry.data?.data?.sort((a: SavedItemTypes, b: SavedItemTypes) => {
                return a.name.localeCompare(b.name);
            });

            if (!isEqual(userWatchlist, _userWatchlist)) {
                setUserWatchlist(_userWatchlist || []);
            }

            if (_userWatchlist.length === 0) {
                setDefaultFilterData();
            }
        }
    }, [
        getAllWatchlistQry.data?.data,
        getAllWatchlistQry.isError,
        getAllWatchlistQry.isLoading,
        getAllWatchlistQry.isRefetching,
        setDefaultFilterData,
        userWatchlist,
    ]);

    const getWatchlistValueQry = sendSingleRequest<CreateSavedItemResponseTypes>(
        {
            includeValues: true,
            savedItems: selectedWatchlist?.id,
            config: { enabled: typeof selectedWatchlist?.id === 'number' && selectedWatchlist?.id >= 0 },
        },
        getSavedItemsByIdConfig,
    );

    useEffect(() => {
        if (
            !getWatchlistValueQry.isLoading &&
            !getWatchlistValueQry.isRefetching &&
            !getWatchlistValueQry.isError &&
            getWatchlistValueQry.data?.data
        ) {
            const value = getWatchlistValueQry.data.data.value as any;
            if (value?.values?.length > 0) {

                const ids = value.values.reduce((acc: WatchlistCompanyIds, item: WatchlistCompanyId) => {
                    if (item?.cfra_company_id) {
                        acc.cfraCompanyId.push(item?.cfra_company_id);
                    } else if (item?.cfra_security_trading_id) {
                        acc.cfraSecurityTradingId.push(item?.cfra_security_trading_id);
                    }
                    return acc;
                }, cloneDeep(initialWatchlistCompanyIds));

                setWatchlistCompanyIds(ids);
            }
        }
    }, [
        getWatchlistValueQry.data?.data,
        getWatchlistValueQry.isError,
        getWatchlistValueQry.isLoading,
        getWatchlistValueQry.isRefetching,
        setWatchlistCompanyIds,
    ]);

    useEffect(() => {
        if (userWatchlist.length && selectedWatchlist === undefined) {
            const lastViewedWatchlist = userWatchlist.find(
                (p: any) => p.id === userPreferences?.preferences?.homepage?.last_viewed_watchlist_id,
            );

            if (lastViewedWatchlist) {
                setSelectedWatchlist(lastViewedWatchlist);
            } else {
                setDefaultFilterData();
            }
        }
    }, [
        selectedWatchlist,
        setDefaultFilterData,
        userPreferences?.preferences?.homepage?.last_viewed_watchlist_id,
        userWatchlist,
    ]);

    const getWatchlistCompaniesFilter = useMemo(() => {
        const filter = [];
        const cfraCompanyId = watchlistCompanyIds?.cfraCompanyId;
        const cfraSecurityTradingId = watchlistCompanyIds?.cfraSecurityTradingId;

        if (cfraCompanyId && cfraSecurityTradingId) {
            if (cfraSecurityTradingId.length > 0) {
                filter.push({ [cfraSecurityTradingField]: { values: cfraSecurityTradingId } });
            }

            if (cfraCompanyId.length > 0) {
                filter.push({ [cfraCompanyIdField]: { values: cfraCompanyId } });
            }

            if (cfraSecurityTradingId.length > 0 || cfraCompanyId.length > 0) {
                return { $or: filter };
            }
            return {};
        }
    }, [watchlistCompanyIds?.cfraCompanyId, watchlistCompanyIds?.cfraSecurityTradingId]);

    const getWatchlistCompaniesQry = sendSingleRequest(
        {
            view: 'companyprofile',
            includeData: true,
            requestBody: {
                filters: {
                    values: getWatchlistCompaniesFilter,
                },
            },
            config: {
                enabled: getWatchlistCompaniesFilter?.$or !== undefined,
            },
        },
        getQueryConfig('getCompanyDetails', 'company'),
    ) as UseQueryResult<ScreenerResearchCompanyData>;

    useEffect(() => {
        if (
            !getWatchlistCompaniesQry.isLoading &&
            getWatchlistCompaniesQry.data &&
            getWatchlistCompaniesQry.data.results.company.length > 0
        ) {
            const _gicsCode: string[] = [];
            getWatchlistCompaniesQry.data?.results?.company.forEach((item) => {
                const indGics = item.company_security.company_sector?._lookup_gics_ind_lid.key;
                if (indGics) {
                    _gicsCode.push(indGics.substring(0, 2), indGics.substring(0, 4), `${indGics}*`);
                }
            });

            setIndResearchGics(_.uniq(_gicsCode));
        }
    }, [getWatchlistCompaniesQry.data, getWatchlistCompaniesQry.isLoading, setIndResearchGics]);

    const WatchListActionMenuItems = useMemo(() => {
        return [
            {
                itemName: renderMenuItem('Rename', rename),
                callback: () => setRenameWatchlist(selectedWatchlist),
            },
            {
                itemName: renderMenuItem('Delete', remove),
                callback: () => setDeleteWatchlist(selectedWatchlist),
            },
        ];
    }, [selectedWatchlist]);

    const refetchWatchlists = async (id?: number) => {
        const { data, isFetching, isError } = await getAllWatchlistQry.refetch();

        if (isFetching || isError) {
            return;
        }

        setSelectedWatchlist(data?.data?.find((company) => company.id === id));
    };

    const handleResetClick = () => {
        setDefaultFilterData();
        setSelectedWatchlist(undefined);
        setPreferences(PreferenceType.HomepageLastViewedWatchlist, undefined);
    };

    const handleOnSelectionChange = (item: SavedItemTypes) => {
        unsetFilterData();
        setSelectedWatchlist(item);
        setPreferences(PreferenceType.HomepageLastViewedWatchlist, item.id);
    };

    const handleOnWatchlistCreated = (createdItem: CreateSavedItemTypes) => {
        setPreferences(PreferenceType.HomepageLastViewedWatchlist, createdItem.id);
        refetchWatchlists(createdItem.id);
    };

    async function handleDeleteConfirmClick() {
        setPreferences(PreferenceType.HomepageLastViewedWatchlist, undefined);
        await refetchWatchlists(deleteWatchlist?.id);
        setDeleteWatchlist(undefined);
    }

    const handleOnRenameConfirm = async (id: number) => {
        await refetchWatchlists(id);
        setRenameWatchlist(undefined);
    };

    const shouldShowExitForm = (ref: any) => {
        return ref?.current?.shouldShowExitForm ? ref?.current?.shouldShowExitForm() : true;
    };

    if (getAllWatchlistQry.isLoading || getAllWatchlistQry.isRefetching) {
        return (
            <div style={{ width: '200px', marginLeft: '10px', marginTop: '10px' }}>
                <Layout.Skeleton height='40px' />
            </div>
        );
    }

    return (
        <Box display='flex'>
            <WatchlistDropdown
                selectedWatchlist={selectedWatchlist}
                userWatchlist={userWatchlist}
                onSelectionChange={handleOnSelectionChange}
                onResetClick={handleResetClick}
                onCreateClick={() => setShowCreateModal(true)}
            />

            {selectedWatchlist?.name && <WatchlistActionMenu menuItems={WatchListActionMenuItems} />}

            {typeof renameWatchlist?.id === 'number' && (
                <RenameSavedItemModal
                    id={renameWatchlist.id}
                    name={renameWatchlist.name}
                    savedItemType={UserSavedItemsLookupID.InstitutionalWatchlist}
                    modalTitle='Rename Watchlist'
                    saveType='Save WatchList'
                    onCancel={() => setRenameWatchlist(undefined)}
                    onConfirm={() => handleOnRenameConfirm(renameWatchlist.id)}
                />
            )}

            {typeof deleteWatchlist?.id === 'number' && (
                <DeleteSavedItemModal
                    id={deleteWatchlist.id}
                    name={deleteWatchlist.name}
                    onCancel={() => setDeleteWatchlist(undefined)}
                    onConfirm={handleDeleteConfirmClick}
                />
            )}

            {showCreateModal && (
                <NewWatchlistModal
                    ref={newWatchlistModalRef}
                    showModal={showCreateModal}
                    setShowModal={setShowCreateModal}
                    onWatchlistCreated={handleOnWatchlistCreated}
                    shouldShowExitForm={() => shouldShowExitForm(companySearchRef)}
                    modalBoxStyles={{ maxWidth: '800px' }}>
                    <NewWatchlistCompanySearchModal
                        ref={companySearchRef}
                        setCreateWatchlistData={(
                            data: { cfra_security_trading_id: string; cfra_company_id: string }[],
                        ) => newWatchlistModalRef?.current?.setCreateWatchlistData(data)}
                    />
                </NewWatchlistModal>
            )}
        </Box>
    );
};

import { ETFLinkButton } from '@cfra-nextgen-frontend/shared';
import exportIcon from '@cfra-nextgen-frontend/shared/src/assets/icons/export.svg';
import { StyledAvatar } from '@cfra-nextgen-frontend/shared/src/components/Avatar/Avatar';
import { Tooltip } from '@cfra-nextgen-frontend/shared/src/components/dataDisplay';
import { tooltipTheme } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import { Box, ThemeProvider } from '@mui/material';

export function ExportButton({ onClick }: { onClick: () => void }) {
    return (
        <ETFLinkButton
            onClick={onClick}
            content={
                <ThemeProvider theme={tooltipTheme}>
                    <Tooltip title={'Export Watchlist'}>
                        <Box display={'flex'}>
                            <StyledAvatar src={exportIcon} />
                            <span
                                style={{
                                    fontSize: '13.5px',
                                    lineHeight: 1.5,
                                    marginLeft: '10px',
                                    color: '#007AB9',
                                }}>
                                Export
                            </span>
                        </Box>
                    </Tooltip>
                </ThemeProvider>
            }
        />
    );
}

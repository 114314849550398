import { CustomSvgIcon } from '@cfra-nextgen-frontend/shared/src/components/Icon/CustomSvgIcon';
import { IconConfiguration } from '@cfra-nextgen-frontend/shared/src/components/Icon/iconConfigurations';
import { Box, CircularProgress, SxProps } from '@mui/material';

type IconWithLoadingProps = {
    icon: React.FC<React.SVGProps<SVGSVGElement>>;
    onClick?: () => void;
    iconConfiguration: IconConfiguration;
    isLoading?: boolean;
    iconSx?: SxProps;
};

export function IconWithLoading({ icon, onClick, iconConfiguration, isLoading, iconSx }: IconWithLoadingProps) {
    return (
        <Box
            sx={{
                width: '18px',
                height: '23.75px',
                display: 'flex',
                alignItems: 'center',
            }}
            onClick={onClick}>
            {isLoading ? (
                <CircularProgress size={15} sx={{ color: 'primary.main' }} />
            ) : (
                <CustomSvgIcon
                    component={icon}
                    viewBox='0 0 18 18'
                    sx={{
                        width: '18px',
                        color: iconConfiguration.iconColor,
                        '&:hover': {
                            fill: iconConfiguration.iconHoverColor,
                            color: iconConfiguration.iconHoverColor,
                        },
                        ...iconSx,
                    }}
                />
            )}
        </Box>
    );
}

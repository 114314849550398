import { ReactComponent as Communication } from '@cfra-nextgen-frontend/shared/src/assets/icons/communication.svg';
import { ReactComponent as CompanyReport } from '@cfra-nextgen-frontend/shared/src/assets/icons/company-report.svg';
import { ReactComponent as Consumer } from '@cfra-nextgen-frontend/shared/src/assets/icons/consumer.svg';
import { ReactComponent as EarningsAlert } from '@cfra-nextgen-frontend/shared/src/assets/icons/earnings-alert.svg';
import { ReactComponent as Energy } from '@cfra-nextgen-frontend/shared/src/assets/icons/energy.svg';
import { ReactComponent as Financial } from '@cfra-nextgen-frontend/shared/src/assets/icons/financial.svg';
import { ReactComponent as Healthcare } from '@cfra-nextgen-frontend/shared/src/assets/icons/healthcare.svg';
import { ReactComponent as Industrials } from '@cfra-nextgen-frontend/shared/src/assets/icons/industrials.svg';
import { ReactComponent as It } from '@cfra-nextgen-frontend/shared/src/assets/icons/it.svg';
import { ReactComponent as LegalAlert } from '@cfra-nextgen-frontend/shared/src/assets/icons/legal-alert.svg';
import { ReactComponent as LegalEducational } from '@cfra-nextgen-frontend/shared/src/assets/icons/legal-educational.svg';
import { ReactComponent as LegalPipeline } from '@cfra-nextgen-frontend/shared/src/assets/icons/legal-pipeline.svg';
import { ReactComponent as LegalReport } from '@cfra-nextgen-frontend/shared/src/assets/icons/legal-report.svg';
import { ReactComponent as Materials } from '@cfra-nextgen-frontend/shared/src/assets/icons/materials.svg';
import { ReactComponent as Notebooks } from '@cfra-nextgen-frontend/shared/src/assets/icons/notebooks.svg';
import { ReactComponent as Other } from '@cfra-nextgen-frontend/shared/src/assets/icons/other.svg';
import { ReactComponent as RealEstate } from '@cfra-nextgen-frontend/shared/src/assets/icons/real-estate.svg';
import { ReactComponent as SpecialInterest } from '@cfra-nextgen-frontend/shared/src/assets/icons/special-interest.svg';
import { ReactComponent as Staples } from '@cfra-nextgen-frontend/shared/src/assets/icons/staples.svg';
import { ReactComponent as Utilities } from '@cfra-nextgen-frontend/shared/src/assets/icons/utilities.svg';
import { ResearchTypeId } from '@cfra-nextgen-frontend/shared/src/utils/enums';

export const sectorImageMap = {
    'Communication Services': Communication,
    'Consumer Discretionary': Consumer,
    'Consumer Staples': Staples,
    Energy: Energy,
    Financials: Financial,
    'Health Care': Healthcare,
    Industrials: Industrials,
    'Information Technology': It,
    Materials: Materials,
    Other: Other,
    'Real Estate': RealEstate,
    Utilities: Utilities,
};

export const reportTypeImageMap = {
    [ResearchTypeId.LegalReport]: LegalReport,
    [ResearchTypeId.LegalAlert]: LegalAlert,
    [ResearchTypeId.LegalEducational]: LegalEducational,
    [ResearchTypeId.FrsCompanyReport]: CompanyReport,
    [ResearchTypeId.FrsNotebooks]: Notebooks,
    [ResearchTypeId.FrsEarningsAlert]: EarningsAlert,
    [ResearchTypeId.FrsIndustryReport]: CompanyReport,
    [ResearchTypeId.FrsEducationalReport]: Notebooks,
    [ResearchTypeId.FrsIRAPS]: EarningsAlert,
    [ResearchTypeId.LegalPipeline]: LegalPipeline,
    [ResearchTypeId.LegalPipelineSpecialInterestList]: SpecialInterest,
};

import { Typography } from '@cfra-nextgen-frontend/shared';
import { Grid, Stack } from '@cfra-nextgen-frontend/shared/src/components/layout';
import { fontFamilies, fontWeights } from '@cfra-nextgen-frontend/shared/src/utils';
import { ThemeProvider } from '@mui/material/styles';
import { CSSProperties } from 'react';

interface HeroSectionProps {
    title: string;
    subtitle: string;
    textLines: string[];
    backgroundImage: string;
}

export function HeroSection({ title, subtitle, textLines, backgroundImage }: HeroSectionProps) {
    const styles: { splashContainer: CSSProperties; subtitle: CSSProperties } = {
        splashContainer: {
            position: 'absolute',
            top: '73px',
            left: 0,
            right: 0,
            bottom: 0,
            transition: 'ease 0.5s',
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            zIndex: 0,
            height: '343px',
        },
        subtitle: {
            fontSize: '22px',
            fontWeight: fontWeights.Medium,
            fontFamily: fontFamilies.GraphikRegular,
            color: '#5D849B',
        },
    };

    return (
        <Grid item xs={12} style={{ height: '343px' }}>
            <Grid item xs={12} style={styles.splashContainer}></Grid>
            <Grid item xs={12} style={{ zIndex: '5', position: 'relative' }}>
                <ThemeProvider theme={{}}>
                    <Grid container>
                        <Grid item sm={12}>
                            <Grid item>
                                <Grid
                                    item
                                    sm={12}
                                    display={'flex'}
                                    justifyContent={'center'}
                                    height={'100%'}
                                    flexDirection={'column'}
                                    paddingLeft={10}
                                    paddingTop={5}>
                                    <Stack>
                                        <Typography
                                            variant='h2'
                                            textAlign={'left'}
                                            style={{
                                                textShadow: '0px 0px 4px #00000080',
                                                color: '#fff',
                                                fontFamily: fontFamilies.GraphikRegular,
                                                fontWeight: 400,
                                                fontSize: '14px',
                                            }}>
                                            HELP & SUPPORT
                                        </Typography>
                                        <Typography
                                            variant='h2'
                                            textAlign={'left'}
                                            style={{
                                                textShadow: '0px 0px 4px #00000080',
                                                color: '#fff',
                                                fontFamily: fontFamilies.GraphikMedium,
                                                fontWeight: 500,
                                                fontSize: '48px',
                                                marginBottom: '16px',
                                            }}>
                                            {title}
                                        </Typography>
                                        {textLines.map((line, index) => (
                                            <Typography
                                                key={index}
                                                style={{
                                                    ...styles.subtitle,
                                                    color: '#fff',
                                                    fontFamily: fontFamilies.GraphikRegular,
                                                    fontSize: '17px',
                                                }}
                                                textAlign={'left'}>
                                                {line}
                                            </Typography>
                                        ))}
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </ThemeProvider>
            </Grid>
        </Grid>
    );
}

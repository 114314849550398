import { Grid } from '@cfra-nextgen-frontend/shared/src/components/layout';
import { ApiNames, fontFamilies, RequestTypes } from '@cfra-nextgen-frontend/shared/src/utils';
import { ThemeProvider } from '@mui/material/styles';
import { PageTheme } from 'components/themes/theme';
import { BiggestConcernTitle } from 'features/home/components/BiggestConcernsCard';
import { AgGirdCard } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGridCard';
import { AgGridReact } from 'ag-grid-react';
import { useRef, useContext, useMemo, useCallback, useState } from 'react';
import { UseQueryResult } from 'react-query';
import { ScreenerResearchCompanyData } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import { extractFromScreenerData } from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/columnDefs';
import { defaultMinWidth } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGrid';
import { ReactComponent as PdfIcon } from '@cfra-nextgen-frontend/shared/src/assets/images/PdfIcon.svg';
import { BiggestConcernToggle } from './BiggestConcersToggle';
import { Layout } from '@cfra-nextgen-frontend/shared';
import { StyledTabs } from '@cfra-nextgen-frontend/cfra-institutional/src/features/home/components/StyledTabs';
import { StyledTab } from '@cfra-nextgen-frontend/cfra-institutional/src/features/home/components/StyledTab';
import '@cfra-nextgen-frontend/shared/src/components/AgGrid/GridThemeV4.scss';
import { getCellRendererValueProcessor } from 'components/AgGrid/renderers';

const containerStyles = {
    backgroundColor: '#FFFF',
    borderRadius: '10px',
};

const viewList: Array<{
    label: string;
    view: string;
    orderBy: string;
    sortDirection: 'asc' | 'desc';
    filters: { [key: string]: any };
}> = [
    {
        label: 'Current',
        view: 'biggest_concerns_full',
        orderBy: 'frs_biggest_concerns_list.addition_date',
        sortDirection: 'desc',
        filters: {
            'frs_biggest_concerns_list.is_biggest_concern': { values: [true] },
        },
    },
    {
        label: 'Removed',
        view: 'biggest_concerns_removed',
        orderBy: 'frs_biggest_concerns_list.removal_date',
        sortDirection: 'desc',
        filters: {
            'frs_biggest_concerns_list.is_biggest_concern': { values: [false] },
        },
    },
];

export function BiggestConcernHome() {
    const { sendSingleRequest } = useContext(ProjectSpecificResourcesContext);
    const gridRef = useRef<AgGridReact>(null);
    const [currentRegion, setCurrentRegion] = useState<string[]>(['2', '3']);
    const [selectedTab, setSelectedTab] = useState(0);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
    };

    const activeView = viewList[selectedTab];

    const bcScreenerQuery = sendSingleRequest?.(
        {
            path: 'company/screener',
            securityType: 'company',
            view: activeView.view,
            orderBy: activeView.orderBy,
            sortDirection: activeView.sortDirection,
            requestBody: {
                filters: {
                    values: {
                        ...activeView.filters,
                        region_lids__forensic: {
                            values: [...currentRegion],
                        },
                    },
                },
            },
            config: {},
        },
        {
            requestType: RequestTypes.POST,
            path: 'company/screener',
            queryKeyFirstElement: 'trendingResearchScreenerQuery',
            apiName: ApiNames.Research,
        },
    ) as UseQueryResult<ScreenerResearchCompanyData>;

    const { minWidths, customFlexibleColumns, columnDefs } = useMemo(() => {
        if (!bcScreenerQuery?.data) {
            return { minWidths: {}, customFlexibleColumns: [], columnDefs: [] };
        }
        const result = extractFromScreenerData({
            screenerData: bcScreenerQuery?.data,
            cardName: 'biggest concern',
            outerGetCellRendererValueProcessor: getCellRendererValueProcessor,
        });
        return result;
    }, [bcScreenerQuery?.data]);

    const bcCompanyList = useMemo(() => {
        const data: any[] = [];
        if (!bcScreenerQuery?.isLoading && bcScreenerQuery?.data?.results?.company) {
            return bcScreenerQuery?.data?.results?.company || [];
        }
        return data;
    }, [bcScreenerQuery?.isLoading, bcScreenerQuery?.data?.results?.company]);

    const getResizableMinWidthForColumn = useCallback(
        (headerName: string) =>
            headerName === 'undefined' ? defaultMinWidth : minWidths[headerName] || defaultMinWidth,
        [minWidths],
    );

    return (
        <ThemeProvider theme={PageTheme}>
            <Grid container sx={containerStyles}>
                <Grid item sm={12} sx={{ padding: '20px 31px 10px', display: 'flex', justifyContent: 'space-between' }}>
                    <BiggestConcernTitle
                        titleStyles={{
                            fontSize: '18px',
                            fontFamily: fontFamilies.GraphikMedium,
                            color: '#002B5A',
                            gap: '15px',
                        }}
                    />
                    <BiggestConcernToggle onRegionChange={(regions) => setCurrentRegion(regions)} />
                </Grid>
                {/* Tabs for Current and Removed */}
                <Grid item sm={12} sx={{ padding: '0 15px', background: '#E4E8F4' }}>
                    <StyledTabs
                        value={selectedTab}
                        variant='scrollable'
                        allowScrollButtonsMobile
                        TabIndicatorProps={{ style: { display: 'none' } }}
                        onChange={handleTabChange}>
                        {viewList.map((view, index) => (
                            <StyledTab key={index} label={view.label} {...Layout.a11yProps(index)} value={index} />
                        ))}
                    </StyledTabs>
                </Grid>
                <Grid item sm={12} sx={{ padding: '22px 31px 0px' }}>
                    {bcScreenerQuery?.isLoading ? (
                        // Show skeleton while loading
                        <Layout.Skeleton height={10} sx={{ marginBottom: '24px' }} />
                    ) : (
                        <AgGirdCard
                            icon={PdfIcon}
                            showDefaultExportButton={false}
                            ref={gridRef}
                            columnDefs={columnDefs}
                            suppressRowClickSelection={true}
                            customFlexibleColumns={customFlexibleColumns}
                            getResizableMinWidthForColumn={getResizableMinWidthForColumn}
                            rowsData={bcCompanyList || []}
                            gridTheme='grid-theme-v4'
                        />
                    )}
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}

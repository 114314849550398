import { useUserEntitlements } from '@cfra-nextgen-frontend/shared/src/hooks/useUserEntitlements';
import { useEffect, useState } from 'react';

export function useUserData() {
    const { userEntitlements } = useUserEntitlements();
    const [userData, setUserData] = useState({
        email: "Not Available",
        first_name: "Not Available",
        last_name: "",
        phone: "Not Available",
        company: "Not Available",
        subject: ""
    });

    useEffect(() => {
        const user = userEntitlements?.user_data?.results?.data?.[0];
        if (user || userEntitlements?.company_name) {
            setUserData({
                email: user?.email || "Not Available",
                first_name: user?.first_name || "Not Available",
                last_name: user?.last_name || "",
                phone: user?.phone_number || "Not Available",
                company: userEntitlements?.company_name || "Not Available",
                subject: ""
            });
        }
    }, [userEntitlements]);

    return userData;
}
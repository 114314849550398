import { Box, SxProps } from '@mui/material';
import { BadgeBiggestConcerns } from 'components/BadgeBiggestConcerns';

export function BcLabel({ containerSx }: { containerSx?: SxProps }) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                ...containerSx,
            }}>
            <BadgeBiggestConcerns />
            <Box sx={{ marginLeft: '5px' }}>Company on the Biggest Concerns List</Box>
        </Box>
    );
}

import { ResearchTypeId } from '@cfra-nextgen-frontend/shared/src/utils';
import { ResearchComponent } from 'components/ResearchComponent';
import { SIZE_WHEN_NO_SIDEBAR } from 'components/ResearchComponent/components/constants';
import { getFiltersJsxVariant1 } from 'components/ResearchComponent/components/getFiltersJsx';
import { SortOptions } from 'utils/enums';

const getFiltersJsx = getFiltersJsxVariant1({
    virtualizeAutocompleteComponentsProps: [
        {
            defaultInputLabel: 'Client',
            filterMetadataKey: 'platinum.research_report_request.client',
        },
        {
            defaultInputLabel: 'Ticker',
            filterMetadataKey: 'insights.research_report_security__trading._primary.research_report_security.security_trading.ticker_symbol',
        },
        {
            defaultInputLabel: 'Company',
            filterMetadataKey: 'insights.research_report_security__company._primary.research_report_security.company.company_name',
        },
        {
            defaultInputLabel: 'Analyst',
            filterMetadataKey: 'insights.research_report_author.analyst_id_analyst',
        },
        {
            defaultInputLabel: 'Status',
            filterMetadataKey: 'platinum.research_report_request.status',
        },
        {
            defaultInputLabel: 'Request Type',
            filterMetadataKey: 'platinum.research_report_request.request_type',
        },
        {
            defaultInputLabel: 'Region',
            filterMetadataKey: 'insights.research_report_security__trading.research_report_security__security_trading__exchange__region_country_lid',
        },
        {
            defaultInputLabel: 'Sector',
            filterMetadataKey: 'insights.research_report_security__company__gics._primary.research_report_security.company_sector.lookup_gics_sector_lid.key',
        },
        {
            defaultInputLabel: 'Sub-Industry',
            filterMetadataKey: 'insights.research_report_security__company__gics._primary.research_report_security.company_sector.lookup_gics_subind_lid.key',
        }
    ]
});

export function BespokeAdmin() {
    return (
        <ResearchComponent
            filtersSectionProps={{
                getFiltersJsx,
            }}
            queriesKeyFirstElementPostfix='bespoke_admin'
            screenerSearchByParams={{
                view: 'bespoke',
                size: SIZE_WHEN_NO_SIDEBAR
            }}
            defaultFilters={{
                'insights.research_report.research_type_id': {
                    values: [ResearchTypeId.FrsBespokeAdminViewer],
                },
            }}
            defaultSortOptions={SortOptions.NoSort}
            showTypeSearchComponent={false}
            showOnlyTableView={true}
        />
    );
}

import { ResearchTypeId } from '@cfra-nextgen-frontend/shared/src/utils/enums';
import { ResearchComponent } from 'components/ResearchComponent';
import { SIZE_WHEN_NO_SIDEBAR } from 'components/ResearchComponent/components/constants';
import { getFiltersJsxVariant1 } from 'components/ResearchComponent/components/getFiltersJsx';
import { reportTypeImageMap } from 'utils/mappings';

const getFiltersJsx = getFiltersJsxVariant1({
    pillsRowComponentProps: {
        filterMetadataKey: 'insights.research_report.research_type_name_pill',
        imageMap: reportTypeImageMap,
        customSortOrder: [
            ResearchTypeId.FrsIndustryReport,
            ResearchTypeId.FrsIRAPS,
            ResearchTypeId.FrsEducationalReport,
        ]
    },
    virtualizeAutocompleteComponentsProps: [
        {
            defaultInputLabel: 'Sector',
            filterMetadataKey: 'insights.research_report.composite_gics_sector_code_autocomplete',
        },
        {
            defaultInputLabel: 'Sub-Industry',
            filterMetadataKey: 'insights.research_report.composite_gics_sub_industry_code',
        },
        {
            defaultInputLabel: 'Author',
            filterMetadataKey: 'insights.research_report_author.analyst_id',
        },
    ],
    dateRangePickerComponentProps: {
        filterMetadataKey: 'insights.research_report.publish_timestamp',
    },
});

export function ResearchIndustryHome() {
    return (
        <ResearchComponent
            filtersSectionProps={{
                getFiltersJsx,
            }}
            queriesKeyFirstElementPostfix='industry'
            screenerSearchByParams={{
                view: 'research_hub',
                size: SIZE_WHEN_NO_SIDEBAR,
            }}
            defaultFilters={{
                'insights.research_report.research_type_id': {
                    values: [
                        ResearchTypeId.FrsIndustryReport,
                        ResearchTypeId.FrsEducationalReport,
                        ResearchTypeId.FrsIRAPS,
                    ],
                },
            }}
        />
    );
}

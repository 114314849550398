import { PageWrapper } from 'components/PageWrapper/PageWrapper';
import { ScoresHome } from 'features/scores/components/ScoresHome';
import { Navigate, Route, Routes } from 'react-router-dom';

export function ScoresRoutes() {
    return (
        <Routes>
            <Route path='/*' element={<Navigate to='.' />} />
            <Route
                path='/'
                element={
                    <PageWrapper>
                        <ScoresHome />
                    </PageWrapper>
                }
            />
        </Routes>
    );
}

import { LinkButton } from '@cfra-nextgen-frontend/shared/src/components/ETFButton/ETFLinkButton';
import { fontFamilies, fontWeights } from '@cfra-nextgen-frontend/shared/src/utils';
import styled from '@emotion/styled';
import Box from "@mui/material/Box";
import CloseIconGrey from 'assets/images/CloseIconGrey.svg';
import { MenuTextStyle } from "./WatchlistDropdown";

export const ButtonMenuItem = styled(LinkButton)(({ theme }) => ({
    ...MenuTextStyle,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '10px',
    fontFamily: fontFamilies.GraphikMedium,
    fontWeight: fontWeights.Medium,
    fontSize: '14px',
}));

export function ResetOption({ onClick }: { onClick: () => void }) {
    return (
        <ButtonMenuItem key='reset' onClick={onClick}>
            <Box component='img' src={CloseIconGrey} />
            <Box sx={{ color: '#3C3C3C' }}>Reset</Box>
        </ButtonMenuItem>
    );
}

import { ProjectSpecificResourcesContext } from "@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context"
import { SavedItemTypes } from "@cfra-nextgen-frontend/shared/src/components/Screener/types/savedItem"
import { CreateSavedItemResponseTypes, CreateSavedItemTypes } from "@cfra-nextgen-frontend/shared/src/components/Screener/types/savedScreens"
import { SearchInputVariant1 } from "@cfra-nextgen-frontend/shared/src/components/SearchInput/SearchInputVariant1"
import { AutoSuggestCompany, Hit } from "@cfra-nextgen-frontend/shared/src/components/TypeSearch/types/opensearch"
import { getSavedItemsByIdConfig, updateSavedItemByIdConfig } from "@cfra-nextgen-frontend/shared/src/utils/userSavedItem"
import { CompanySearchCell } from "components/companySearch"
import { useCallback, useContext, useEffect, useState } from "react"
import { useSnackbar } from 'notistack';
import { DefaultCFRASnack } from "@cfra-nextgen-frontend/shared"
import { SnackMessageForm } from "@cfra-nextgen-frontend/shared/src/components/Snack/SnackMessageForm"
import { Box, CircularProgress, createTheme } from "@mui/material"

const customTheme = createTheme({
    components: {
        MuiStack: {
            styleOverrides: {
                root: {
                    width: '290px',
                    border: '1px solid #E4E4E4',
                    borderRadius: '10px',
                    background: '#FFF'
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    paddingTop: '0px !important',
                    paddingBottom: '0px !important',
                    height: '42px'
                }
            }
        }
    }
});

export type UpdateWatchlistBySearchProps = {
    selectedWatchlist?: SavedItemTypes;
    onWatchlistUpdated?: (updatedItem: CreateSavedItemTypes) => void;
}

export const UpdateWatchlistBySearch = ({ selectedWatchlist, onWatchlistUpdated }: UpdateWatchlistBySearchProps) => {
    const { sendSingleRequest } = useContext(ProjectSpecificResourcesContext);
    const [updatedList, setUpdatedList] = useState<any[]>();
    const [selectedRowData, setSelectedRowData] = useState<Hit<AutoSuggestCompany>>();
    const { enqueueSnackbar } = useSnackbar();
    const ShowSnack = DefaultCFRASnack(enqueueSnackbar);

    if (!sendSingleRequest) {
        throw new Error('sendSingleRequest is not defined');
    }
    
    const getWatchlistValueQry = sendSingleRequest<CreateSavedItemResponseTypes>({
        savedItems: selectedWatchlist?.id || 0,
        includeValues: true,
        config: { enabled: (selectedWatchlist?.id && selectedWatchlist?.id > 0) ? true : false }
    }, getSavedItemsByIdConfig);
    
    
    const updateWatchlist = useCallback((rowData: Hit<AutoSuggestCompany>) => {
        if (
            getWatchlistValueQry &&
            !getWatchlistValueQry.isRefetching &&
            !getWatchlistValueQry.isError &&
            getWatchlistValueQry?.data?.data
        ) {
            const value = getWatchlistValueQry.data.data.value as any;
            const existingData: any[] = value?.values || [];
            const securityTradingId = rowData._source["company_security.security_trading.cfra_security_trading_id"]?.toString();
            const companyId = rowData._source["company_security.company.cfra_company_id"]?.toString()
            const isAlreadyPresent = existingData.findIndex((data) => {
                return (data.cfra_security_trading_id === securityTradingId || (!securityTradingId && data.cfra_company_id === companyId)) ? true : false
            }) > -1
            
            if (!isAlreadyPresent) {
                existingData.push({
                    "cfra_security_trading_id": securityTradingId,
                    "cfra_company_id": companyId
                });
                setUpdatedList(existingData);
            } else {
                ShowSnack(SnackMessageForm({
                    message: `${rowData._source["company_security.company.company_name"]} is already part of watchlist ${selectedWatchlist?.name}`
                }));
            }
        }
    }, [getWatchlistValueQry, ShowSnack, selectedWatchlist]);

    const updateWatchListQueryResult = sendSingleRequest<CreateSavedItemResponseTypes>(
        {
            savedItems: selectedWatchlist?.id || -1,
            requestBody: {
                value: {
                    values: updatedList,
                },
            },
            config: {
                enabled: (updatedList?.length && updatedList?.length > 0) ? true : false,
            },
        },
        updateSavedItemByIdConfig,
    );

    useEffect(() => {
        if (
            updateWatchListQueryResult &&
            updateWatchListQueryResult.isSuccess &&
            !updateWatchListQueryResult.isError &&
            updateWatchListQueryResult?.data?.data
        ) {
            ShowSnack(SnackMessageForm({
                message: `${selectedRowData?._source["company_security.security_trading.ticker_symbol"]} was added to ${selectedWatchlist?.name}`
            }));
            setSelectedRowData(undefined);
            onWatchlistUpdated?.(updateWatchListQueryResult?.data.data);
        }

        if (!updateWatchListQueryResult.isLoading) {
            setUpdatedList(undefined);
        }
    }, [
        updateWatchListQueryResult,
        updateWatchListQueryResult?.data?.data,
        ShowSnack,
        selectedWatchlist,
        onWatchlistUpdated,
        selectedRowData
    ])

    if (updateWatchListQueryResult?.isLoading) {
        return (
            <Box paddingRight={'50px'} display={'flex'} alignItems={'center'}><CircularProgress size={20} sx={{ color: 'primary.main' }} /></Box>
        )
    }

    return (
        <CompanySearchCell 
            SearchInputComponent={SearchInputVariant1} 
            searchInputComponentProps={{ 
                placeholder: 'Search to add companies', 
                hideClearButton: false,
                customTheme,
                autoFocusInput: false
            }}
            onResultOptionClick={(rowIndex: string, rowData: Hit<AutoSuggestCompany>) => {
                setSelectedRowData(rowData);
                updateWatchlist(rowData);
            }}
            clearInputOnSelection={true}
        />
    )
}
import Analyst from '@cfra-nextgen-frontend/shared/src/assets/icons/analyst.svg';
import BiggestConcern from '@cfra-nextgen-frontend/shared/src/assets/icons/bc.svg';
import BespokeEdge from '@cfra-nextgen-frontend/shared/src/assets/icons/chart-with-human.svg';
import BespokeAdmin from '@cfra-nextgen-frontend/shared/src/assets/icons/user.svg';
import Industry from '@cfra-nextgen-frontend/shared/src/assets/icons/chart.svg';
import CompanyResearch from '@cfra-nextgen-frontend/shared/src/assets/icons/company.svg';
import Library from '@cfra-nextgen-frontend/shared/src/assets/icons/library.svg';
import BespokeServices from '@cfra-nextgen-frontend/shared/src/assets/icons/tree.svg';
import { MenuItem } from '@cfra-nextgen-frontend/shared/src/components/TopNavigation/SharedTopNavigation';
import { UserEntitlementsResponseData } from '@cfra-nextgen-frontend/shared/src/types/userEntitlements';
import { PageNames, PageNamesToRoutePaths } from 'utils/enums';
import { LookupItems, ResearchTypeId } from '@cfra-nextgen-frontend/shared/src/utils/enums';

export const topNavItems: Record<string, MenuItem> = {
    Home: {
        name: 'Home',
        href: '/',
        type: 'link',
    },
    AccountingLens: {
        name: 'Accounting Lens',
        href: '/accounting-lens',
        type: 'tab',
        popup: {
            title: 'Accounting Lens',
            description:
                'Enhance financial decision-making with advanced analytics and insights. Optimize your accounting processes for better performance and compliance.',
            links_title: 'Links',
            links: [
                {
                    icon: BiggestConcern,
                    name: PageNames.AccountingLensBiggestConcern,
                    href: PageNamesToRoutePaths[PageNames.AccountingLensBiggestConcern],
                },
                {
                    icon: CompanyResearch,
                    name: PageNames.AccountingLensCompanyResearch,
                    href: PageNamesToRoutePaths[PageNames.AccountingLensCompanyResearch],
                },
                {
                    icon: Industry,
                    name: PageNames.AccountingLensIndustryResearch,
                    href: PageNamesToRoutePaths[PageNames.AccountingLensIndustryResearch],
                },
            ],
        },
    },
    ResearchHub: {
        name: PageNames.ResearchHub,
        href: PageNamesToRoutePaths[PageNames.ResearchHub],
        type: 'tab',
        popup: {
            title: PageNames.ResearchHub,
            description:
                'Explore our specialized services and unique research, tailored to your specific needs. Our expert team provides personalized solutions delivering exceptional insights and precision.',
            links_title: 'Links',
            links: [
                { icon: Library, name: PageNames.ResearchHub, href: PageNamesToRoutePaths[PageNames.ResearchHub] },
                { icon: Analyst, name: 'CFRA Analysts', href: '/accounting-lens/reports' },
            ],
        },
    },
    Scores: {
        name: PageNames.Scores,
        href: PageNamesToRoutePaths[PageNames.Scores],
        type: 'link',
    },
    LegalEdge: {
        name: PageNames.LegalEdge,
        href: PageNamesToRoutePaths[PageNames.LegalEdge],
        type: 'link',
    },
    Bespoke: {
        name: 'Bespoke',
        href: '/bespoke',
        type: 'tab',
        popup: {
            title: 'Bespoke Solutions',
            description:
                'Explore our specialized services and unique research, tailored to your specific needs. Our expert team provides personalized solutions delivering exceptional insights and precision.',
            links_title: 'Links',
            links: [
                { icon: BespokeServices, name: PageNames.BespokeServices, href: PageNamesToRoutePaths[PageNames.BespokeServices] },
                { icon: BespokeEdge, name: PageNames.BespokeEdge, href: PageNamesToRoutePaths[PageNames.BespokeEdge] },
                {
                    icon: BespokeAdmin,
                    name: 'Bespoke Admin',
                    href: '/bespoke/admin',
                    shouldHidelink: (userEntitlements?: UserEntitlementsResponseData) => {
                        let hide = true;
                        userEntitlements?.packages?.forEach((pckg) => {
                            if (pckg.entitlements) {
                                if (
                                    pckg.entitlements.find(
                                        (ent) =>
                                            ent.item_lid === LookupItems.ResearchTypeId &&
                                            ent.value.toString() === ResearchTypeId.FrsBespokeAdminViewer.toString(),
                                    )
                                ) {
                                    hide = false;
                                    return hide;
                                }
                            }
                        });
                        return hide;
                    },
                },
            ],
        },
    },
    Watchlist: {
        name: 'Watchlist',
        href: '/watchlist',
        type: 'link',
    },
};

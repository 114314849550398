import { Grid } from '@cfra-nextgen-frontend/shared/src/components/layout/Grid';
import { Typography } from '@cfra-nextgen-frontend/shared';
import { Link } from '@mui/material';
import { ReactComponent as PhoneIcon } from '@cfra-nextgen-frontend/shared/src/assets/icons/PhoneIcon.svg';
import { ReactComponent as EmailIcon } from '@cfra-nextgen-frontend/shared/src/assets/icons/EmailIcon.svg';
import IconButton from '@mui/material/IconButton';
import { fontFamilies } from '@cfra-nextgen-frontend/shared/src/utils';

const contactPoints = [
    {
        label: 'Client Services',
        details: [
            {
                type: 'phone',
                icon: <PhoneIcon />,
                text: '800 220 0502',
                link: 'tel:8002200502',
                style: { color: '#93939F', textDecoration: 'none', fontWeight: '500', fontFamily: fontFamilies.GraphikMedium, },
            },
            {
                type: 'email',
                icon: <EmailIcon />,
                text: 'cservices@cfraresearch.com',
                link: 'mailto:cservices@cfraresearch.com',
                style: { color: '#007BB8', textDecoration: 'none', fontWeight: '500', fontFamily: fontFamilies.GraphikMedium, },
            },
        ],
    },
];

export function Contacts() {
    return (
        <Grid container direction='column' spacing={2} sx={{ marginLeft: 0, maxWidth: '377px', margin: 0 }}>
            <Typography
                variant='h4'
                sx={{
                    fontSize: '18px',
                    fontFamily: fontFamilies.GraphikMedium,
                    color: '#002B5A',
                    marginBottom: '20px',
                }}>
                Points Of Contact
            </Typography>
            {contactPoints.map((point, index) => (
                <Grid
                    item
                    key={index}
                    sx={{ border: '1px #C7C3C3 solid', borderRadius: '20px', padding: '16px 18px 20px' }}>
                    <Typography
                        variant='h6'
                        sx={{
                            color: '#222',
                            fontSize: '18px',
                            fontWeight: '600',
                            fontFamily: fontFamilies.GraphikSemibold,
                            marginBottom: '5px',
                            marginLeft: '5px',
                        }}>
                        {point.label}
                    </Typography>
                    <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                        {point.details.map((detail, i) => (
                            <li key={i} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                                <IconButton style={{ marginRight: '8px' }}>{detail.icon}</IconButton>
                                <Link href={detail.link} color='inherit' sx={{ ...detail.style }}>
                                    {detail.text}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </Grid>
            ))}
        </Grid>
    );
}

import { Button } from '@cfra-nextgen-frontend/shared';
import { Grid } from '@mui/material';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import { useForm } from 'react-hook-form';
import { useContext, useState, useEffect } from 'react';
import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import { ApiNames, fontFamilies, RequestTypes } from '@cfra-nextgen-frontend/shared/src/utils';
import { useUserData } from './useUserData';
import { useSnackbar } from 'notistack';

const styles = {
    label: {
        fontFamily: fontFamilies.GraphikMedium,
        fontSize: '16px',
        color: '#002B5A',
        display: 'flex',
        fontWeight: '500',
        span: {
            fontFamily: fontFamilies.GraphikRegular,
            fontSize: '13px',
            fontWeight: '400',
        },
        lineHeight: '2em',
    },
    inputField: {
        width: '100%',
        backgroundColor: '#F9FAFC',
        borderRadius: '8px',
        color: '#7A7777',
        padding: 0,
        fontFamily: fontFamilies.GraphikMedium,
        fontWeight: '500',
        '& .MuiInputBase-input': {
            padding: '15px 23px 10px',
            fontFamily: fontFamilies.GraphikMedium,
            fontWeight: '500',
            color: '#7A7777',
            fontSize: '15px',
            lineHeight: '2.13em',
        },
        '& .MuiInputBase-root textarea': {
            padding: '0',
            fontFamily: fontFamilies.GraphikMedium,
            fontWeight: '500',
            color: '#7A7777',
            fontSize: '15px',
            lineHeight: '2.13em',
        },
    },
    span: {
        fontFamily: fontFamilies.GraphikRegular,
        color: 'red',
    },
};

interface FormData {
    name: string;
    company: string;
    phone: string;
    email: string;
    message: string;
}

export function ContactForm({
    thankyouMessage = 'Thank you for reaching out to us. Your query has been received, and our team will respond to you as soon as possible',
    subjectPrefix = 'Support Request',
    hasSubjectField = false
}) {
    const userData = useUserData();
    const { sendSingleRequest } = useContext(ProjectSpecificResourcesContext);
    const {
        reset,
        register,
        handleSubmit,
        formState: { errors },
        setError,
        setValue,
        clearErrors,
    } = useForm();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formData, setFormData] = useState<FormData | null>(null);
    const [notification, setNotification] = useState<string | null>(null);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (userData) {
            setValue('name', `${userData.first_name} ${userData.last_name}`);
            setValue('company', userData.company || '');
            setValue('phone', userData.phone || '');
            setValue('email', userData.email || '');
        }
    }, [userData, setValue]);

    const onSubmit = (data: any) => {
        clearErrors('form');

        const fieldsToValidate = [
            { name: 'name', message: "Your Name cannot be 'Not Available'." },
            { name: 'company', message: "Company cannot be 'Not Available'." },
            { name: 'phone', message: "Phone Number cannot be 'Not Available'." },
            { name: 'email', message: "Your email address cannot be 'Not Available'." },
            { name: 'message', message: "Your message cannot be 'Not Available'." },
        ];

        let hasError = false;

        fieldsToValidate.forEach(({ name, message }) => {
            if (!data[name] || data[name] === 'Not Available') {
                setError(name, {
                    type: 'manual',
                    message: message,
                });
                hasError = true;
            }
        });

        if (hasError) {
            return;
        }

        const newFormData = {
            name: `${userData.first_name} ${userData.last_name}`,
            phone: data.phone,
            email: userData.email,
            message: data.message,
            company: data.company,
            subject: hasSubjectField ? `${subjectPrefix} ${data.subject}` :`${subjectPrefix} ${userData.first_name} ${userData.last_name}`,
        };

        setFormData(newFormData);
        setIsSubmitting(true);
    };

    useEffect(() => {
        if (notification) {
            enqueueSnackbar(notification, { variant: 'success' });
            setNotification('');
            reset();
            setIsSubmitting(false);
            if (userData) {
                setValue('name', `${userData.first_name} ${userData.last_name}`);
                setValue('company', userData.company || '');
                setValue('phone', userData.phone || '');
                setValue('email', userData.email || '');
            }
        }
    }, [notification, enqueueSnackbar, reset, setValue, userData]);

    if (!sendSingleRequest) {
        throw new Error('sendSingleRequest is not defined');
    }

    const response = sendSingleRequest(
        {
            requestBody: formData,
            config: {
                enabled: isSubmitting,
            },
            noErrorOnNoKeyValuePairs: true,
        },
        {
            requestType: RequestTypes.POST,
            path: 'send-email',
            queryKeyFirstElement: 'sendEmail',
            apiName: ApiNames.UserManagement,
        },
    );

    useEffect(() => {
        if (!response?.isLoading && response?.data) {
            if ((response?.data as any)?.data.message_id) {
                setNotification(thankyouMessage);
            }
        }
    }, [response?.isLoading, response?.data, thankyouMessage]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container direction='column' spacing={2}>
                <Grid item>
                    <FormLabel htmlFor='name' sx={{ ...styles.label }}>
                        Name
                    </FormLabel>
                    <TextField
                        fullWidth
                        defaultValue={
                            userData.first_name && userData.last_name
                                ? `${userData.first_name} ${userData.last_name}`
                                : `${userData.first_name}`
                        }
                        {...register('name', { required: true })}
                        variant='outlined'
                        InputProps={{
                            style: { ...styles.inputField },
                        }}
                        sx={styles.inputField}
                    />
                    {errors.name && (
                        <Grid item>
                            <span style={{ ...styles.span }}>This field is required</span>
                        </Grid>
                    )}
                </Grid>
                <Grid item>
                    <FormLabel htmlFor='company' sx={{ ...styles.label }}>
                        Company
                    </FormLabel>
                    <TextField
                        fullWidth
                        {...register('company', { required: true })}
                        variant='outlined'
                        defaultValue={userData.company}
                        sx={styles.inputField}
                        onChange={(e) => {
                            setValue('company', e.target.value);
                        }}
                    />
                    {errors.company && (
                        <Grid item>
                            <span style={{ ...styles.span }}>This field is required</span>
                        </Grid>
                    )}
                </Grid>
                <Grid item>
                    <FormLabel htmlFor='phone' sx={{ ...styles.label }}>
                        Phone Number
                    </FormLabel>
                    <TextField
                        fullWidth
                        {...register('phone', { required: true })}
                        variant='outlined'
                        defaultValue={userData.phone}
                        sx={styles.inputField}
                        onChange={(e) => {
                            setValue('phone', e.target.value);
                        }}
                    />
                    {errors.phone && (
                        <Grid item>
                            <span style={{ ...styles.span }}>This field is required</span>
                        </Grid>
                    )}
                </Grid>
                <Grid item>
                    <FormLabel htmlFor='email' sx={{ ...styles.label }}>
                        Email &nbsp;<span>(so we can reply to you)</span>
                    </FormLabel>
                    <TextField
                        fullWidth
                        {...register('email', { required: true })}
                        defaultValue={userData.email}
                        variant='outlined'
                        InputProps={{
                            style: { ...styles.inputField },
                        }}
                        sx={styles.inputField}
                    />
                    {errors.email && (
                        <Grid item>
                            <span style={{ ...styles.span }}>This field is required</span>
                        </Grid>
                    )}
                </Grid>
                {hasSubjectField ? 
                    <Grid item>
                        <FormLabel htmlFor='subject' sx={{ ...styles.label }}>
                            Subject
                        </FormLabel>
                        <TextField
                            fullWidth
                            {...register('subject', { 
                                required: true,
                                maxLength: 40 
                            })}
                            placeholder='Subject of your request'
                            variant='outlined'
                            InputProps={{
                                style: { ...styles.inputField },
                            }}
                            sx={styles.inputField}
                        />
                        {errors.subject && (
                            <Grid item>
                                {errors.subject.type === 'required' && (
                                    <span style={{ ...styles.span }}>This field is required</span>
                                )}
                                {errors.subject.type === 'maxLength' && (
                                    <span style={{ ...styles.span }}>Character limit is 40, including spaces</span>
                                )}
                            </Grid>
                        )}
                    </Grid> : ''
                }
                <Grid item>
                    <FormLabel htmlFor='message' sx={{ ...styles.label }}>
                        Message
                    </FormLabel>
                    <TextField
                        fullWidth
                        multiline
                        rows={4}
                        {...register('message', { required: true })}
                        placeholder='Submit questions or comments'
                        variant='outlined'
                        sx={{ ...styles.inputField }}
                    />
                    {errors.message && (
                        <Grid item>
                            <span style={{ ...styles.span }}>This field is required</span>
                        </Grid>
                    )}
                </Grid>

                <Grid item>
                    <Button
                        variant='contained'
                        color='primary'
                        type='submit'
                        disabled={isSubmitting}
                        style={{
                            backgroundColor: '#002B5A',
                            color: '#FFFFFF',
                            textTransform: 'initial',
                            borderRadius: '8px',
                            padding: '13px 33px',
                        }}>
                        {isSubmitting ? 'Submitting...' : 'Submit'}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
}

import { defaultMinWidth } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGrid';
import { AgGirdCard } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGridCard';
import { AgGridSelectedRowsContextProvider } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGridSelectedRowsContext/AgGridSelectedRowsContext';
import '@cfra-nextgen-frontend/shared/src/components/AgGrid/GridThemeV2.scss';
import { CardHeaderVariant1 } from '@cfra-nextgen-frontend/shared/src/components/CardHeaders/CardHeaderVariant1';
import { ETFCard } from '@cfra-nextgen-frontend/shared/src/components/ETFCard';
import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import { FiltersModalContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/FiltersModalContext';
import { ChipItem } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/types';
import {
    useFiltersForm,
    UseFiltersFormExternalInputProps,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/forms/hooks/useFiltersForm';
import { ScreenerResearchCompanyData } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { extractFromScreenerData } from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/columnDefs';
import { watchListColumnWidth } from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/constants';
import { getRowID } from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/ssr';
import { SearchInputVariant1Ref } from '@cfra-nextgen-frontend/shared/src/components/SearchInput/SearchInputVariant1';
import { Title } from '@cfra-nextgen-frontend/shared/src/components/Typography/StyledTitles';
import { Grid } from '@cfra-nextgen-frontend/shared/src/components/layout';
import { ApiNames, RequestTypes } from '@cfra-nextgen-frontend/shared/src/utils';
import { getFiltersReqBody } from '@cfra-nextgen-frontend/shared/src/utils/api';
import { AgGridReact } from 'ag-grid-react';
import { getCellRendererValueProcessor } from 'components/AgGrid/renderers';
import { ExportButton } from 'components/watchlists/ExportButton';
import { useWatchlistActions } from 'components/watchlists/hooks/useWatchlistActions';
import { cloneDeep, debounce } from 'lodash';
import { forwardRef, useCallback, useContext, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { UseQueryResult } from 'react-query';
import { companyFiltersRequestParams } from 'utils/api';
import { getFiltersJsx } from './getFiltersJsx';
import { ToggleOptions } from './utils';

const size = 25;
const sendSingleRequestConfig = {
    path: 'company/screener',
    apiName: ApiNames.Research,
    requestType: RequestTypes.POST,
    queryKeyFirstElement: 'researchCompanyScreenerQuery',
};

type ScoresCardProps = {
    watchlist: Omit<ReturnType<typeof useWatchlistActions>, 'unselectWatchlist'>;
    externalFilters?: Record<string, any>;
    externalChipItems?: Record<string, ChipItem>;
    onExternalChipDeleteClick?: UseFiltersFormExternalInputProps['onExternalChipDeleteClick'];
};

const cashFlowFilterMetadataKey = 'frs_cash_flow_score_drivers.decile';
const earningsFilterMetadataKey = 'frs_earnings_score_drivers.decile';

export const ScoresCard = forwardRef<SearchInputVariant1Ref, ScoresCardProps>(
    (
        {
            watchlist: {
                createWatchlistButtonJsx,
                watchlistSelectJsx,
                operationsModalsJsx,
                selectedWatchlist,
                watchlistOperationsMenuIconJsx,
            },
            externalFilters,
            externalChipItems,
            onExternalChipDeleteClick,
        },
        ref,
    ) => {
        const { sendSingleRequest, getDataSource, getSsrDataExportFn, exportSSRAgGrid } = useContext(
            ProjectSpecificResourcesContext,
        );
        const [selectedToggleOption, setSelectedToggleOption] = useState<ToggleOptions>(ToggleOptions.All);
        const [searchTerm, setSearchTerm] = useState<string>();

        if (!sendSingleRequest) {
            throw new Error('sendSingleRequest is not defined');
        }

        if (!getDataSource) {
            throw new Error('getDataSource is not defined');
        }

        if (!getSsrDataExportFn) {
            throw new Error('getSsrDataExportFn is not defined');
        }

        if (!exportSSRAgGrid) {
            throw new Error('exportSSRAgGrid is not defined');
        }

        const clearSearchRef = useRef<SearchInputVariant1Ref>(null);

        useImperativeHandle(ref, () => ({
            clearSearch: () => clearSearchRef.current?.clearSearch(),
        }));

        const { filtersFormJsx, filtersChipPanelJsx, filtersData } = useFiltersForm({
            filtersRequestParams: companyFiltersRequestParams,
            getFiltersJsx: getFiltersJsx({
                toggleProps: {
                    options: Object.values(ToggleOptions),
                    defaultSelectionIndex: Object.values(ToggleOptions).indexOf(selectedToggleOption),
                    onChange: (index) => setSelectedToggleOption(Object.values(ToggleOptions)[index]),
                },
                searchInputProps: {
                    onChange: debounce((e) => setSearchTerm(e.target.value), 200),
                    clearButtonCallback: () => setSearchTerm(undefined),
                    placeholder: 'Company Search',
                    ref: clearSearchRef,
                },
            }),
            externalChipItems,
            onExternalChipDeleteClick,
            chipStyles: {
                containerSx: {
                    padding: '0px',
                },
                onChipItemsExistSxProps: {
                    borderTop: '1px dotted #CCCCCC',
                    paddingTop: '24px',
                    paddingBottom: '15px',
                },
            },
            formStyle: {
                marginBottom: '24px',
            },
        });

        const metadataKeyToAllValues = useMemo(() => {
            return Object.keys(filtersData?.data || {}).reduce((acc, metadataKey) => {
                acc[metadataKey] = filtersData?.data?.[metadataKey]?.items?.map((item) => item.key) || [];
                return acc;
            }, {} as Record<string, Array<string> | Array<number>>);
        }, [filtersData]);

        const gridRef = useRef<AgGridReact>(null);

        const { filtersPostData } = useContext(FiltersModalContext);

        const requestBody = useMemo(() => {
            let updatedFiltersPostData = cloneDeep(filtersPostData) || {};

            switch (selectedToggleOption) {
                case ToggleOptions.All:
                    // if all selected - filter by both columns with the same values
                    if (Object.keys(updatedFiltersPostData).includes(cashFlowFilterMetadataKey)) {
                        updatedFiltersPostData[earningsFilterMetadataKey] =
                            updatedFiltersPostData[cashFlowFilterMetadataKey];
                    }
                    break;
                case ToggleOptions.EarningsScore:
                    // if earnings selected - filter values in easrnings column to show only numbers (no '-' values)
                    if (!Object.keys(updatedFiltersPostData).includes(cashFlowFilterMetadataKey)) {
                        updatedFiltersPostData[cashFlowFilterMetadataKey] = {
                            values: metadataKeyToAllValues[earningsFilterMetadataKey],
                        };
                    }
                    // move cash flow filter to earnings filter
                    updatedFiltersPostData[earningsFilterMetadataKey] =
                        updatedFiltersPostData[cashFlowFilterMetadataKey];
                    // make sure that cash flow filter is not applied
                    delete updatedFiltersPostData[cashFlowFilterMetadataKey];
                    break;
                case ToggleOptions.CashFlow:
                    // is cash flow selected - filter values in cash flow column to show only numbers (no '-' values)
                    if (!Object.keys(updatedFiltersPostData).includes(cashFlowFilterMetadataKey)) {
                        updatedFiltersPostData[cashFlowFilterMetadataKey] = {
                            values: metadataKeyToAllValues[cashFlowFilterMetadataKey],
                        };
                    }
                    // make sure that earnings filter is not applied
                    delete updatedFiltersPostData[earningsFilterMetadataKey];
                    break;
            }

            return getFiltersReqBody({ ...updatedFiltersPostData, ...externalFilters });
        }, [filtersPostData, externalFilters, selectedToggleOption, metadataKeyToAllValues]);

        const screenerCommonSearchByParams = useMemo(() => {
            return {
                securityType: 'research',
                view: 'scores',
                requestBody: requestBody,
                search: searchTerm,
            };
        }, [requestBody, searchTerm]);

        const searchByParams = useMemo(() => {
            return {
                ...screenerCommonSearchByParams,
                size,
                config: {
                    enabled: true,
                },
            };
        }, [screenerCommonSearchByParams]);

        const companiesQuery = sendSingleRequest(
            searchByParams,
            sendSingleRequestConfig,
        ) as UseQueryResult<ScreenerResearchCompanyData>;

        const { minWidths, customFlexibleColumns, columnDefs } = useMemo(() => {
            if (!companiesQuery.data) {
                return { minWidths: {}, customFlexibleColumns: [], columnDefs: [] };
            }

            let result = extractFromScreenerData({
                screenerData: companiesQuery.data,
                cardName: selectedWatchlist?.name || 'inst_watchlist',
                outerGetCellRendererValueProcessor: getCellRendererValueProcessor,
            });

            switch (selectedToggleOption) {
                case ToggleOptions.All:
                    break;
                case ToggleOptions.CashFlow:
                    result.columnDefs = result.columnDefs.filter((columnDef) => {
                        if (columnDef.field === earningsFilterMetadataKey) {
                            return false;
                        }
                        return columnDef;
                    });
                    break;
                case ToggleOptions.EarningsScore:
                    result.columnDefs = result.columnDefs.filter((columnDef) => {
                        if (columnDef.field === cashFlowFilterMetadataKey) {
                            return false;
                        }
                        return columnDef;
                    });
                    break;
            }

            return result;
        }, [companiesQuery, selectedWatchlist, selectedToggleOption]);

        const getResizableMinWidthForColumn = useCallback(
            (headerName: string) =>
                headerName === 'undefined' ? watchListColumnWidth : minWidths[headerName] || defaultMinWidth,
            [minWidths],
        );

        const ssrDataSource = useMemo(() => {
            return getDataSource({
                metadataFields: companiesQuery?.data?._metadata.fields || [],
                etfData: companiesQuery?.data?.results?.company || [],
                requestParams: {
                    path: 'company/screener',
                    ...screenerCommonSearchByParams,
                },
                _resultsKey: 'company',
                size,
            });
        }, [
            companiesQuery?.data?._metadata.fields,
            companiesQuery?.data?.results?.company,
            getDataSource,
            screenerCommonSearchByParams,
        ]);

        const totalResultsText = (function () {
            const totalResults = companiesQuery?.data?.results?.total;
            return !companiesQuery?.isLoading && !totalResults
                ? 'Total Results: No records found'
                : `Total Results: ${companiesQuery?.isLoading ? 'Loading...' : totalResults}`;
        })();

        const SSRDataExportFn = useMemo(
            () =>
                getSsrDataExportFn?.({
                    metadataFields: companiesQuery?.data?._metadata.fields || [],
                    requestParams: {
                        path: 'company/screener',
                        ...screenerCommonSearchByParams,
                    },
                    _resultsKey: 'company',
                }),
            [companiesQuery?.data?._metadata.fields, getSsrDataExportFn, screenerCommonSearchByParams],
        );

        const exportCallback = useCallback(() => {
            exportSSRAgGrid(
                gridRef,
                SSRDataExportFn,
                {
                    autoSizeColumns: true,
                    fileName: 'CFRA Scores',
                    metadata: {
                        cardName: 'CFRA Scores',
                    },
                    sheetName: 'Data Details',
                    useMaxAsOfDate: undefined,
                    getFormattedValuesFirst: true,
                },
                companiesQuery?.data?._metadata.fields || [],
            );
        }, [companiesQuery?.data?._metadata.fields, gridRef, exportSSRAgGrid, SSRDataExportFn]);

        return (
            <ETFCard containerStyles={{ paddingBottom: '0px', paddingTop: '22px' }}>
                <Grid
                    item
                    sm={12}
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}>
                    <Title
                        sx={{
                            lineHeight: 1,
                            paddingBottom: '22px',
                        }}>
                        Filter
                    </Title>
                    <ExportButton onClick={() => exportCallback()} />
                </Grid>
                {filtersFormJsx}
                <Grid item sm={12} display={'flex'} flexDirection={'row'} alignItems={'center'} paddingBottom={'36px'}>
                    {watchlistSelectJsx}
                    <div style={{ marginLeft: 10 }}>{createWatchlistButtonJsx}</div>

                    {selectedWatchlist && <div>{watchlistOperationsMenuIconJsx}</div>}
                    {operationsModalsJsx}
                </Grid>
                {filtersChipPanelJsx}
                <Grid item sm={12}>
                    <CardHeaderVariant1
                        title={selectedWatchlist?.name}
                        subTitle={totalResultsText}
                        containerStyles={{
                            borderTop: '1px solid #AAAAAA',
                            paddingTop: '13px',
                            paddingBottom: '0px',
                        }}
                    />
                    <AgGridSelectedRowsContextProvider isSSRMEnabled={true}>
                        <AgGirdCard
                            ref={gridRef}
                            useSSRMode
                            getRowID={getRowID}
                            SSRrowsToFetch={size}
                            embedFullWidthRows={true}
                            columnDefs={columnDefs}
                            gridTheme='grid-theme-v2'
                            SSRDataSource={ssrDataSource}
                            showDefaultExportButton={false}
                            suppressRowClickSelection={true}
                            customFlexibleColumns={customFlexibleColumns}
                            getResizableMinWidthForColumn={getResizableMinWidthForColumn}
                            labelProps={{ width: '100%' }}
                            labelPanelContainerStyles={{ paddingTop: '36px' }}
                            rowMultiSelectWithClick={true}
                            rowSelection='multiple'
                        />
                    </AgGridSelectedRowsContextProvider>
                </Grid>
            </ETFCard>
        );
    },
);

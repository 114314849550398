import { Box, Chip, styled } from '@mui/material';

export type PillProps = {
    label: string;
    count?: number;
    itemKey: number;
    field: any;
    onChipClick: () => void;
    useSecondaryStyle?: boolean;
    imageMap: Record<string, React.FC<React.SVGProps<SVGSVGElement>> | undefined>;
};

const StyledChip = styled(Chip)(() => ({
    backgroundColor: '#F3F5FC',
    color: '#5A5A5A',
    fontFamily: 'GraphikMedium',
    fontSize: '13px',
    whiteSpace: 'normal',
    padding: '10px 20px 10px 18px',
    height: '48px',
    borderRadius: '30px',
    gap: '7px',
    '& .MuiChip-avatar': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0px',
    },
    '& .MuiChip-avatar img': {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
        margin: '0px',
    },
    '& .MuiChip-label': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0px',
    },
    '& .sector-count': {
        color: '#007BB8',
        marginLeft: '11px',
    },
}));

export function Pill(props: PillProps) {
    const Icon = props.imageMap[props.itemKey] || props.imageMap[props.label];

    return (
        <StyledChip
            sx={
                props.useSecondaryStyle
                    ? {
                          backgroundColor: '#ffffff',
                          border: '1px solid #007BB8',
                          '&:hover': {
                              bgcolor: 'rgba(0, 0, 0, 0.12) !important',
                          },
                      }
                    : {}
            }
            label={
                <>
                    <span
                        style={{
                            display: 'block',
                            maxWidth: '104px',
                            overflowWrap: 'break-word',
                            wordBreak: 'break-word',
                            whiteSpace: 'normal',
                            lineHeight: '1',
                            paddingRight: '0px',
                        }}>
                        {props.label}
                    </span>
                    <span className='sector-count' style={{ marginLeft: '8px' }}>
                        {props.count}
                    </span>
                </>
            }
            avatar={
                Icon && (
                    <Box>
                        <Icon />
                    </Box>
                )
            }
            onClick={props.onChipClick}
            clickable
        />
    );
}

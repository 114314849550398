import { Grid } from '@cfra-nextgen-frontend/shared/src/components/layout/Grid';
import { ScoresInfoBanner } from 'features/scores/components/ScoresInfoBanner';
import { horizontalPaddingInSu } from 'utils/lookAndFeel';
import { ScoresCardWithContexts } from './scoresCard/ScoresCardWithContexts';

export function ScoresHome() {
    return (
        <Grid container spacing={horizontalPaddingInSu}>
            <Grid item xs={12}>
                <ScoresInfoBanner />
            </Grid>
            <Grid item sm={12}>
                <ScoresCardWithContexts />
            </Grid>
        </Grid>
    );
}

import { AgGridCardInfiniteCSM } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGridCardInfiniteCSM';
import { CardHeaderVariant1 } from '@cfra-nextgen-frontend/shared/src/components/CardHeaders/CardHeaderVariant1';
import { CFRASelectVariant3 } from '@cfra-nextgen-frontend/shared/src/components/CFRASelect/CFRASelectVariant3';
import { ETFCard } from '@cfra-nextgen-frontend/shared/src/components/ETFCard';
import { InfiniteOptions } from '@cfra-nextgen-frontend/shared/src/components/InfiniteScroll/InfiniteOptions';
import {
    ItemVariant3,
    ItemVariant3Container,
} from '@cfra-nextgen-frontend/shared/src/components/ItemComponents/ItemVariant3';
import { Grid } from '@cfra-nextgen-frontend/shared/src/components/layout';
import { GridViewsPanel, Views } from '@cfra-nextgen-frontend/shared/src/components/layout/ETFButtonsPannel/ViewsPanel';
import {
    getRequestParamsPropsVariant1,
    researchLinkGetterParams,
} from '@cfra-nextgen-frontend/shared/src/components/LinkGetter/researchLinkGetter';
import { FiltersModalContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/FiltersModalContext';
import { ResultsContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultsContext';
import { DataItem, ScreenerResearchData } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { GetRequestParamsProps } from '@cfra-nextgen-frontend/shared/src/hooks/useLinkGetter';
import { getFiltersReqBody, SearchByParams } from '@cfra-nextgen-frontend/shared/src/utils/api';
import {
    joinArraysAndGetUniqueValues,
    replaceOldArrayWithNewOne,
} from '@cfra-nextgen-frontend/shared/src/utils/lodashHelpers';
import { SxProps } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { getCellRendererValueProcessor } from 'components/AgGrid/renderers';
import { BadgeBiggestConcerns } from 'components/BadgeBiggestConcerns';
import {
    getScreenerInfiniteOptionsProps,
    getScreenerInfiniteRequestParamsConfig,
} from 'features/home/components/InfiniteOptions/screener';
import { cloneDeep, isEqual, mergeWith } from 'lodash';
import moment from 'moment';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { sortPrimaryFirst } from 'utils/arrays';
import { SortOptions } from 'utils/enums';

const bestMatchSortOptions = {
    orderBy: undefined,
    sortDirection: undefined,
};

const selectOptionsToSortOptions: Record<SortOptions, { orderBy?: string; sortDirection?: 'asc' | 'desc' }> = {
    [SortOptions.BestMatch]: bestMatchSortOptions,
    [SortOptions.PublishDateAsc]: {
        orderBy: 'research_report.publish_timestamp',
        sortDirection: 'asc',
    },
    [SortOptions.PublishDateDesc]: {
        orderBy: 'research_report.publish_timestamp',
        sortDirection: 'desc',
    },
    [SortOptions.NoSort]: {}
};

function getResultsCount(count: number) {
    if (count === -1) {
        return 'Loading...';
    }
    return count;
}

export function SearchResults({
    searchTerm,
    externalPostData = {},
    defaultFilters,
    defaultSortOptions = SortOptions.PublishDateDesc,
    queryKeyFirstElementPostfix,
    externalSearchByParams,
    showOnlyTableView,
    showTopLevelSortOptions,
    gridViewItemContainerSx
}: {
    searchTerm?: string;
    externalPostData?: Record<string, any>;
    defaultFilters?: Record<string, any>;
    defaultSortOptions?: SortOptions | undefined;
    queryKeyFirstElementPostfix?: string;
    externalSearchByParams?: SearchByParams;
    showOnlyTableView?: boolean;
    showTopLevelSortOptions?: boolean;
    gridViewItemContainerSx?: SxProps;
}) {
    const [selectedView, setSelectedView] = useState<Views>(showOnlyTableView ? Views.TableView : Views.GridView);
    const [selectedSortOption, setSelectedSortOption] = useState<SortOptions | undefined>(defaultSortOptions);

    const { filtersPostData } = useContext(FiltersModalContext);

    const size = useMemo(() => {
        if (!externalSearchByParams?.size) {
            throw new Error('externalSearchByParams.size is required in SearchResults');
        }

        return externalSearchByParams.size;
    }, [externalSearchByParams]);

    // take 50% of fetching size, assume each item height is 48px
    const scrollThresholdPx = useMemo(() => Math.round((48 * size) / 2), [size]);

    const filtersReqBody = useMemo(() => {
        const result = cloneDeep(filtersPostData) || {};
        mergeWith(result, externalPostData, joinArraysAndGetUniqueValues);
        mergeWith(result, defaultFilters, replaceOldArrayWithNewOne);

        return getFiltersReqBody(result);
    }, [filtersPostData, externalPostData, defaultFilters]);

    // set initial searchByParams state
    const [searchByParams, _setSearchByParams] = useState<SearchByParams>({
        path: 'research/screener',
        size: size,
        securityType: 'research',
        requestBody: filtersReqBody,
        search: searchTerm,
        ...externalSearchByParams,
        // if user entered search term - switch the order to best match
        ...(Boolean(searchTerm) && bestMatchSortOptions),
        ...((selectedSortOption  && selectedSortOption !== SortOptions.NoSort && selectOptionsToSortOptions[selectedSortOption]) || {}),
    });

    // handle filters changes and search term changes without unnecessary re-renders
    useEffect(() => {
        _setSearchByParams((oldParams) => {
            const isSearchTermChanged = oldParams.search !== searchTerm;

            if (isSearchTermChanged) {
                // if user entered search term - switch the order to best match
                setSelectedSortOption(SortOptions.BestMatch);
            }

            const newParams = {
                ...oldParams,
                requestBody: filtersReqBody,
                search: searchTerm,
                // if user entered search term - switch the order to best match
                ...(searchTerm && isSearchTermChanged && bestMatchSortOptions),
            };

            if (isEqual(oldParams, newParams)) {
                return oldParams;
            }

            return newParams;
        });
    }, [filtersReqBody, searchTerm]);

    // create a function to update searchByParams state without unnecessary re-renders
    const updateSearchByParams = useCallback((_newParams: SearchByParams) => {
        _setSearchByParams((oldParams) => {
            const newParams = {
                ...oldParams,
                ..._newParams,
            };

            if (isEqual(oldParams, newParams)) {
                return oldParams;
            }

            const newSelectOption = Object.entries(selectOptionsToSortOptions).find(([selectOption, sortOptions]) => {
                return (
                    sortOptions.orderBy === newParams.orderBy && sortOptions.sortDirection === newParams.sortDirection
                );
            });

            if (newSelectOption) {
                setSelectedSortOption(newSelectOption?.[0] as SortOptions);
            } else {
                // display the selected sort option as "Selected Sort" if the sort option is not in the select options
                setSelectedSortOption(undefined);
            }

            return newParams;
        });
    }, []);

    // handle sort select changes without unnecessary re-renders
    useEffect(() => {
        _setSearchByParams((oldParams) => {
            const newParams = {
                ...oldParams,
                ...((selectedSortOption && selectOptionsToSortOptions[selectedSortOption]) || {}),
            };

            if (isEqual(oldParams, newParams)) {
                return oldParams;
            }

            return newParams;
        });
    }, [selectedSortOption]);

    const navigate = useNavigate();

    const {
        chipStateManager: {
            chipStateDispatcher,
            chipState: { resultCount },
        },
    } = useContext(ResultsContext);

    const gridRef = useRef<AgGridReact>(null);

    const calculateInViewRef = useRef<() => void>();

    const onPillClickHandler = useCallback(
        (url: string) => {
            navigate(url);
        },
        [navigate],
    );

    const setResultsCount = useCallback(
        (newResultCount: number) => {
            if (newResultCount === resultCount || (newResultCount < 0 && resultCount < 0)) {
                return;
            }

            chipStateDispatcher({
                type: 'SetResultCount',
                newState: {
                    resultCount: newResultCount,
                },
            });
        },
        [chipStateDispatcher, resultCount],
    );

    const handleResultsCount: (pages?: Array<ScreenerResearchData>) => void = useCallback(
        (pages) => {
            if (!pages) {
                setResultsCount(-1);
                return;
            }

            setResultsCount(pages[0]?.results?.total);
        },
        [setResultsCount],
    );

    const screenerInfiniteRequestParamsConfig = useMemo(
        () => ({
            queryKeyFirstElement: 'researchScreenerQuery',
            ...getScreenerInfiniteRequestParamsConfig<'research'>(size, 'research'),
        }),
        [size],
    );

    const extendedScreenerInfiniteRequestParamsConfig = useMemo(() => {
        return {
            ...screenerInfiniteRequestParamsConfig,
            queryKeyFirstElement: `${screenerInfiniteRequestParamsConfig.queryKeyFirstElement}${queryKeyFirstElementPostfix}`,
        };
    }, [queryKeyFirstElementPostfix, screenerInfiniteRequestParamsConfig]);

    const gridView = useMemo(() => {
        return (
            <InfiniteOptions<ScreenerResearchData, DataItem>
                key='gridView'
                OptionsContainer={ItemVariant3Container}
                infiniteRequestParams={[
                    {
                        ...searchByParams,
                        config: {
                            enabled: true,
                        },
                    },
                    extendedScreenerInfiniteRequestParamsConfig,
                ]}
                RowComponent={({ rowData }) => {
                    const orderedAuthors = sortPrimaryFirst(rowData?.research_report_author);
                    const orderedCompanies = sortPrimaryFirst(rowData?.research_report_security__trading);

                    return (
                        <ItemVariant3<ScreenerResearchData>
                            bottomPillItemsThreshold={3}
                            topText={rowData?.research_report?.research_type_name}
                            topSubText={moment(rowData?.research_report.publish_timestamp).format('MMMM D, YYYY')}
                            middleTitle={rowData?.research_report?.title}
                            middleSubText={rowData?.research_report?.teaser || ''}
                            bottomTextItems={orderedAuthors.map((author: any) => author?.platinum?.analyst?.pen_name)}
                            bottomPillItems={orderedCompanies?.map((company: any) => ({
                                content: company?.research_report_security?.security_trading?.ticker_symbol,
                                onClick: () =>
                                    onPillClickHandler(
                                        `/company-profile/security/${company.research_report_security?.security_trading?.cfra_security_trading_id}`,
                                    ),
                            }))}
                            maxMiddleSubTextLength={125}
                            useLinkGetterParams={researchLinkGetterParams}
                            handleOuterSetRequestParamsProps={(
                                setRequestParamsProps: (props: GetRequestParamsProps) => void,
                            ) => {
                                setRequestParamsProps(getRequestParamsPropsVariant1(rowData?.id));
                            }}
                            topTextRightSlot={
                                rowData?.frs_biggest_concerns_list?.is_biggest_concern && <BadgeBiggestConcerns />
                            }
                            containerStyles={gridViewItemContainerSx}
                        />
                    );
                }}
                noResultsFoundBoxSxProps={{ paddingLeft: '8px' }}
                {...getScreenerInfiniteOptionsProps<'research'>('research')}
                externalCallback={(data) => handleResultsCount(data)}
                scrollThresholdPx={scrollThresholdPx}
            />
        );
    }, [
        onPillClickHandler,
        searchByParams,
        extendedScreenerInfiniteRequestParamsConfig,
        scrollThresholdPx,
        handleResultsCount,
        gridViewItemContainerSx,
    ]);

    const tableView = useMemo(() => {
        return (
            <AgGridCardInfiniteCSM
                key='tableView'
                ref={gridRef}
                searchByParams={{
                    ...searchByParams,
                    config: {
                        enabled: true,
                    },
                }}
                infiniteRequestParamsConfig={extendedScreenerInfiniteRequestParamsConfig}
                scrollThresholdPx={scrollThresholdPx}
                setCalculateInView={(calculateInView) => (calculateInViewRef.current = calculateInView)}
                size={size}
                setResults={(data) => handleResultsCount(data)}
                updateSearchByParams={updateSearchByParams}
                outerGetCellRendererValueProcessor={getCellRendererValueProcessor}
            />
        );
    }, [
        searchByParams,
        calculateInViewRef,
        updateSearchByParams,
        extendedScreenerInfiniteRequestParamsConfig,
        scrollThresholdPx,
        size,
        handleResultsCount,
    ]);

    const searchResultsHeader = useMemo(() => {
        return (
            <CardHeaderVariant1
                title='Search Results'
                subTitle={`Total Results: ${getResultsCount(resultCount)}`}
                slot1Icon={showTopLevelSortOptions ? true : false}
                slot2={showTopLevelSortOptions ?
                    <CFRASelectVariant3
                        selectItems={Object.values(SortOptions)}
                        handleSelectionChange={(e) => {
                            setSelectedSortOption(e.target.value as SortOptions);
                        }}
                        currentSelection={selectedSortOption}
                        placeholder='Selected Sort'
                    />: undefined
                }
                slot3={!showOnlyTableView ?
                    <GridViewsPanel
                        onClickCallback={(view) => {
                            setSelectedView(view);

                            if (view === Views.TableView) {
                                setTimeout(() => calculateInViewRef.current?.(), 200);
                            }
                        }}
                        defaultViewSelected={selectedView}
                    /> : undefined
                }
            />
        );
    }, [resultCount, selectedSortOption, selectedView, showOnlyTableView, showTopLevelSortOptions]);

    return (
        <ETFCard
            containerStyles={{
                padding: '8px 24px 36px 24px',
                transition: 'height 5s ease-out, max-height 5s ease, min-height 5s ease-out,',
                height: 'auto',
                minHeight: 'auto',
                maxHeight: 'auto',
                borderRadius: '8px',
            }}>
            <Grid container>{searchResultsHeader}</Grid>
            {selectedView === Views.GridView && gridView}
            {selectedView === Views.TableView && tableView}
        </ETFCard>
    );
}

import moreIcon from '@cfra-nextgen-frontend/shared/src/assets/icons/three-dots.svg';
import { Item } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { CommonFormComponentProps } from '@cfra-nextgen-frontend/shared/src/components/Form/types/form';
import { Pill, PillProps } from '@cfra-nextgen-frontend/shared/src/components/Pill/Pill';
import { PillMore } from '@cfra-nextgen-frontend/shared/src/components/Pill/PillMore';
import { Box, Button, Menu, styled } from '@mui/material';
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';

const StyledButton = styled(Button)(({ theme }) => ({
    backgroundColor: '#F3F5FC',
    color: '#5A5A5A',
    fontFamily: 'GraphikMedium',
    fontSize: '13px',
    minWidth: '56px',
    width: '56px',
    height: '48px',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'none',
    padding: '0',
    boxShadow: 'none',
    borderRadius: '30px',
    '& .MuiButton-label': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '24px',
        height: '24px',
    },
    '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.12)',
        boxShadow: 'none',
    },
}));

const StyledMenu = styled(Menu)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '11px',
    letterSpacing: 0,
    lineHeight: 1,
    '& .MuiMenu-paper': {
        borderRadius: '10px',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
        padding: '14px 12px 7px 11px',
    },
    '& .MuiList-root': {
        padding: '0',
        display: 'flex',
        flexDirection: 'column',
        '& .MuiMenuItem-root': {
            padding: '6px',
            '&:hover': {
                backgroundColor: '#F3F5FC',
            },
        },
    },
}));

export type FormPillsRowProps = {
    options: Array<Item>;
    customSortOrder?: Array<number | string>;
    hideItemsOutOfCustomSortOrder?: boolean;
    secondaryStyleStartIndex?: number;
    imageMap: PillProps['imageMap'];
    optionsToOverride?: Array<Item>;
} & CommonFormComponentProps;

export function FormPillsRow({
    submitHandler,
    options,
    name,
    control,
    customSortOrder,
    secondaryStyleStartIndex,
    imageMap,
    optionsToOverride,
}: FormPillsRowProps) {
    const [visibleItems, setVisibleItems] = useState<Array<any>>([]);
    const [showPillsAmount, setShowPillsAmount] = useState<number>(0);
    const [moreItems, setMoreItems] = useState<Array<any>>([]);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const moreButtonWidth = 56;
    const gap = 8;
    const pillWidth = 200;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    useEffect(() => {
        const updatePillsVisibility = () => {
            if (containerRef.current) {
                const containerWidth = containerRef.current.offsetWidth;
                const maxVisiblePills = Math.floor((containerWidth - moreButtonWidth) / (pillWidth + gap));

                if (maxVisiblePills !== showPillsAmount) {
                    setShowPillsAmount(maxVisiblePills);
                }
            }
        };

        const currentContainer = containerRef.current;
        const resizeObserver = new ResizeObserver(updatePillsVisibility);

        if (currentContainer) {
            resizeObserver.observe(currentContainer);
        }

        updatePillsVisibility();

        return () => {
            if (currentContainer) {
                resizeObserver.unobserve(currentContainer);
            }
        };
    }, [moreButtonWidth, pillWidth, gap, showPillsAmount]);

    useEffect(() => {
        optionsToOverride?.forEach((optionToOverride) => {
            const index = options.findIndex((option) => option.key === optionToOverride.key);
            if (index !== -1) {
                options[index] = optionToOverride;
            }
        });

        const sortedItems = (function () {
            if (!customSortOrder) {
                options.sort((a: any, b: any) => a.value.localeCompare(b.value));
            }
            const customSortOrderConverted = customSortOrder?.map((item) => String(item)) || [];
            return options.sort(function (a, b) {
                // Pass a function to the sort that takes 2 elements to compare
                // Substract indexes, If element `a` comes first in the array, the returned value will be negative, resulting in it being sorted before `b`, and vice versa.
                return (
                    (customSortOrderConverted?.indexOf(String(a.key)) || 0) -
                    (customSortOrderConverted?.indexOf(String(b.key)) || 0)
                );
            });
        })();

        const newVisibleItems = sortedItems.slice(0, showPillsAmount);
        const newMoreItems = sortedItems.slice(showPillsAmount);

        if (
            JSON.stringify(newVisibleItems) !== JSON.stringify(visibleItems) ||
            JSON.stringify(newMoreItems) !== JSON.stringify(moreItems)
        ) {
            setVisibleItems(newVisibleItems);
            setMoreItems(newMoreItems);
        }
    }, [showPillsAmount, options, visibleItems, moreItems, customSortOrder]);

    const handleMoreMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMoreMenuClose = () => {
        setAnchorEl(null);
    };

    const handleChipClick = useCallback(
        (item: any, field: any) => {
            const newData = { value: item.value, key: item.key, count: item.count };

            const currentValues = field.value || [];

            const updatedValues = [...currentValues, newData];

            field.onChange(updatedValues);
            submitHandler?.();
        },
        [submitHandler],
    );

    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => (
                <div
                    ref={containerRef}
                    style={{
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        minWidth: '100%',
                        gap: '8px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                    {visibleItems.map((item, index) => (
                        <Fragment key={`pill-${item.key}${index}`}>
                            {secondaryStyleStartIndex && index === secondaryStyleStartIndex && (
                                <Box style={{ borderLeft: '1px solid #CCCCCC', height: '48px' }}></Box>
                            )}
                            <Pill
                                key={item.key}
                                itemKey={item.key}
                                label={item.value}
                                count={item.count}
                                field={field}
                                onChipClick={() => handleChipClick(item, field)}
                                useSecondaryStyle={
                                    secondaryStyleStartIndex !== undefined && index >= secondaryStyleStartIndex
                                }
                                imageMap={imageMap}
                            />
                        </Fragment>
                    ))}
                    {showPillsAmount < options.length && (
                        <>
                            <StyledButton variant='contained' onClick={handleMoreMenuClick}>
                                <img src={moreIcon} alt='More Options' />
                            </StyledButton>
                            <StyledMenu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMoreMenuClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                sx={{ marginTop: '10px' }}>
                                {moreItems.map((item: any) => (
                                    <PillMore
                                        key={item.key}
                                        itemKey={item.key}
                                        label={item.value}
                                        count={item.count}
                                        field={field}
                                        onChipClick={() => handleChipClick(item, field)}
                                        imageMap={imageMap}
                                    />
                                ))}
                            </StyledMenu>
                        </>
                    )}
                </div>
            )}
        />
    );
}
